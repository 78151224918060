/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import Cookies from 'js-cookie';
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import styled from "styled-components"
import { useLocation, useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
// import "./switch.css";
import { show, hide } from "react-functional-modal"
import Invoice from "../Invoice/Invoice";
import html2pdf from 'html2pdf.js';
import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import "../../../src/index.css";
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../utils/config";
import { v4 as uuidv4 } from "uuid";
import { Modal as AntModal, Input, Form, Upload, message, Spin, } from "antd";
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require("aws-sdk");
const Pbutton = styled.button
  `
outline: none;
border: none;
margin-left: 18px;
background: #105293;
padding: 3px 5px;

`
const ProviderContainer = styled.div
  `
background: white;
min-width: 60%;
// max-width: 90%;
border: 1px solid rgb(0, 148, 255);
position: relative;
padding: 10px;
border-radius: 20px
`
const ListContainer = styled.div
  `
background: #e3e6f0;
width: 90%;
paddind: 10px;
padding: 10px;
font-size: 14px;
font-weight: 600;
border-radius: 20px
`



const LogisticsCreateOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const { order_id }: any = location.state || {};
  if (!order_id) {
    history.push("/issues");
    // toast.error("Un-Authorized");
    return null; //
  }
  const { baseNodeapiUrl } = config
  const [showPop, setShowPop] = useState(false);
  const fullUrl = window.location.host;
  const toggle = (e: any) => {
    e.preventDefault();
    setShowPop(true);
  };

  let params = useParams();
  console.log("baseNodeapiUrl", baseNodeapiUrl);
  // const { id: order_id }: any = params;
  const [alldetails, setalldetails] = useState<any>([]);
  const decoded = tokenDecode();
  const { data } = decoded;
  const { permission: { order }, } = data;
  const [isChecked, setIsChecked] = useState(false);
  const [orderCancelPopup, setOrderCancelPopup] = useState(false);
  const [isModalIssueVisible, setIsModalIssueVisible] = useState(false);
  const [cancelProviderData, setCancelProviderlist] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [reason_code, setreason_code] = useState();
  const [Loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issueID, setIssueId] = useState(false);
  const [trackDetails, setTrackDetails]: any = useState("");
  const [_onIssueStatus, setOnIssueStatus]: any = useState("");
  const [modalVisibility, setModalVisibility] = useState({
    isModalIssueVisible: false,
    isModalIssueTrackVisible: false,
    orderCancelPopup: false,
  });
  const [updateOrder_, setUpdateOrder_] = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
    return_amount: 0,
  });

  const [orderState, setOrderState] = useState({
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });

  // const [isChecked, setisChecked]: any = useState("");
  const [cancelvalue, setCancelvalue]: any = useState("");
  // const [cancelPartialreson, setCancelPartialreson]: any = useState("");
  const [logisticCharges, setlogisticCharges]: any = useState("")
  let rawdata;
  const [orderCancle, setOrderCancle]: any = useState({
    order_id,
    order_status: "Created",
    product_id: [],
  });
  const [sendingOrderStatus, setsendingOrderStatus] = useState(false);
  // const [addButton, setaddButton] = useState(true);
  // const [basicInfoError, setBasicInfoError] = useState(rawErrorData);
  const [loadingStates, setLoadingStates] = useState({
    trackIssueStatus: false,
    shipmentStatusLoading: false,
    statusTrackLoading: false,
    deferUpdateLoading: false,
  });
  // const [shipmentStatusLoading, setshipmentStatusLoading] = useState(false)
  const [raiseIsseuLoading, setraiseIsseuLoading] = useState(false)
  const [trackIssueStatus, setTrackIssueStatus] = useState(false)
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [unsolicatedCancelReasonModalOpen, setunsolicatedCancelReasonModalOpen] = useState(false);
  const [unsolicatedCancelReasonValue, setunsolicatedCancelReasonValue] = useState("");
  const [providersModalOpen, setprovidersModalOpen] = useState(false)
  const [transaction_data, settransaction_data] = useState("")
  const [trackData, setTrackData]: any = useState({});
  const [imageSrc, setImageSrc] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    "Cancelled",
    // "Returned",
  ]);
  const initialSteps = [
    'Searching-for-Agent',
    'Agent-assigned',
    'Order-picked-up',
    'Out-for-delivery',
    'Order-delivered',
  ];

  const showModal = (src: any) => {
    setImageSrc(src);
    setIsModalVisible(true);
  };
  const imagehandleCancel = () => {
    setIsModalVisible(false);
  };
  const [steps, setSteps] = useState(initialSteps)
  const handleModal = (modalType: string, action: string) => {
    setModalVisibility((prev) => ({
      ...prev,
      [modalType]: action === 'show',
    }));
  };
  const setBtnLoading = (key: any, value: any) => {
    setLoadingStates(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleIssueOk = () => handleModal('isModalIssueVisible', 'hide');
  // const handleIssueTrackOk = () => handleModal('isModalIssueTrackVisible', 'hide');
  // const handleIssueTrackOk = async () => {
  //   await checkTrackStatus(_onIssueStatus?.issue_id, _onIssueStatus?.transaction_id);
  // };
  const handleIssueTrackCancel = () => handleModal('isModalIssueTrackVisible', 'hide');
  const handleIssueCancel = () => handleModal('isModalIssueVisible', 'hide');
  const handleCancel = () => handleModal('orderCancelPopup', 'hide');
  const handleOk = () => handleModal('orderCancelPopup', 'hide');
  const textStyle1 = {
    fontWeight: "600", fontSize: "17px", color: "black"
  }

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleIssueTrackOk = () => {
    AntModal.confirm({
      title: 'Are you sure you want to close?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Close',
      cancelText: 'Cancel',
      onOk: async () => {
        setUpdating(true);
        try {
          const response = await axios.post(`${baseNodeapiUrl}/adapter/logisticsigm/v1/issue_close`, {
            issueId: trackDetails?.message?.issue?.id,
            transactionId: trackDetails?.context?.transaction_id,
          });
          message.success(`Issue closed successfully!`);
          setTimeout(() => {
            handleModal('isModalIssueTrackVisible', 'hide');
            setUpdating(false);
          }, 2000);
          console.log("response", response);
        } catch (error) {
          console.error('Error closing issue:', error);
          message.error('Failed to close the issue. Please try again.');
        }
      }
    });
  };

  const getorderDetails = async (order_id: any) => {
    let apiEndpoint = baseUrlDefiner();
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      });

      if (data.status === 200) {
        setIsLoading(false);
        getProgres_Status(data?.logistics[0]?.transaction_id, data?.logistics[0]?.message_id);
        settransaction_data(data?.logistics[0]?.transaction_id ?? null);
        getTrackIssue(data?.logistics[0]?.transaction_id);
        setorderDetail(data.products);
        setOrderCancle(data.products);
        setInvoiceHolder({
          path: data.data[0].invoice !== null ? data.data[0].invoice : "",
          origin: data.data[0].invoice !== null ? "database" : "manual"
        })
        setlogisticCharges(JSON.parse(data.data[0]?.quote))
        setalldetails(data);
        let totalP = 0;
        data.products.forEach((item: any) => {
          totalP += parseFloat(item?.price);
        });
        if (data.data[0].order_state !== "") {
          const index = orderStatus.indexOf(data.data[0].order_state);
          if (index !== -1) {
            setorderStatus(orderStatus.slice(index));
          }
        }
        setOrderState({
          ...orderState,
          order_state: data.data[0]?.order_state,
          tracking_url: data.products[0]?.tracking_url,
          fulfillment_state: data.products[0]?.fulfillment_state,
        });
        // setCancelProviderlist(data);
        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          return_amt:
            JSON.parse(data.data[0]?.order_total) - data.data[0].return_amount,
          total_price: JSON.parse(data.data[0].order_total),
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });

        rawdata = data.products.map((item: any, i: any) => ({
          item_id: item.product_id,
          order_status: orderState.order_state,
          qty: cancelvalue,
        }));

        orderCancle.product_id = Object.assign(rawdata);
        setOrderCancle(orderCancle);

      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  // console.log("isLoading----->", isLoading);

  const updateOrder = async (product_id: any) => {
    let apiEndpoint = baseUrlDefiner();
    try {

      const getItem = orderDetail.find(
        (item: any) => item?.product_id === product_id
      );

      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: getItem,
      });

      if (data.message === "flag1") {
        toast.success("Item status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);
    }
  };


  const cancelOrder = async (product_id: any) => {
    let apiEndpoint = baseUrlDefiner();
    console.log("apiEndpoint", apiEndpoint)
    try {
      const { data } = await axios({
        // url: `${apiEndpoint}/cancel`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: "",
      });

      if (data.message === "flag1") {
        toast.success("Order Cancelled Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);
    }
  };
  const updateOrderChangeHandler = (e: any, index: any) => {
    const newUpdate: any = orderDetail;
    newUpdate[index][e.target.name] = e.target.value;
    setorderDetail([...newUpdate]);
  };
  const updateThisOrder = async (e: any) => {
    let uri;
    let apiEndpoint = NodebaseUrlDefiner();
    if (fullUrl === "multipleselleradmin.thesellerapp.com") {
      uri = `${apiEndpoint}/admin/api/order_status_update`
    } else {
      uri = `${apiEndpoint}/updateorderstatus`
    }

    try {
      const { data } = await axios({
        url: uri,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: { ...orderState, "reason_id": null },
      });

      if (data?.code == 200 || data?.status == 200) {
        const index = orderStatus.indexOf(orderState.order_state);
        if (index !== -1) {
          setorderStatus(orderStatus.slice(index));
        }
        if (fullUrl === "multipleselleradmin.thesellerapp.com") {
          sendStatusHandler()
        }

        if (orderState.order_state === "Created") {
          toast.success("Order Created successfully!");
        } else if (orderState.order_state === "Accepted") {
          toast.success("Order Accepted successfully!");
        } else if (orderState.order_state === "In-progress") {
          toast.success("Order In-progress!");
        } else if (orderState.order_state === "Completed") {
          toast.success("Order Completed successfully!");
        } else if (orderState.order_state === "Cancelled") {
          toast.success("Order Cancelled successfully!");
        }

      } else {
        toast.error("Something went wrong !");
      }
    } catch (error) {
      toast.error("Something went wrong 500 !");
    }
  };
  const unsolicatedCancel = async (e: any) => {
    let apiEndpoint = NodebaseUrlDefiner();
    try {
      console.log(" { ...orderState, reason_code: unsolicatedCancelReasonValue }----", { ...orderState, reason_code: unsolicatedCancelReasonValue, order_status: orderState?.order_state })

      const { data } = await axios({
        url: `${apiEndpoint}/v1/unsolicated_cancel`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: { ...orderState, reason_code: unsolicatedCancelReasonValue, order_status: orderState?.order_state },
      });
      console.log("data-------->", data)

      if (data?.message?.ack?.status === "ACK") {
        toast.success("Updated Successfully!")
        sendStatusHandler()
      } else {
        toast.error("Something went wrong !");
      }
    } catch (error) {
      toast.error("Something went wrong 500 !");
    }
  };


  const cancleQty = (item: any, setCancelvalue: any) => {
    item.qty = setCancelvalue;
    // setCancelvalue(cancelvalue);
    // console.log("value", item.qty);
  };
  const cancleReason = (item: any, setCancelPartialreson: any) => {
    item.reason = setCancelPartialreson;
    // setCancelPartialreson(cancelPartialreson);
    // console.log("reason", item.reason);
  };
  const cancleChecked = (item: any, setisChecked: any) => {
    item.Checked = setisChecked;
    // setCancelPartialreson(cancelPartialreson);
    // console.log("Checked", item.Checked);
  };
  const [cancelQuantityl, setcancelQuantityl] = useState("")
  const [reasonCode, setreasonCode] = useState("")
  const [cancellingProduct, setcancellingProduct] = useState("")
  const [showGreaterValueAlert, setshowGreaterValueAlert] = useState(false)
  const [showEnterValueAlert, setshowEnterValueAlert] = useState(false)
  const [showCancelQuantityGreaterthenOrderQuantity, setshowCancelQuantityGreaterthenOrderQuantity] = useState(false)
  const cancleUpdate = (
    cancelvalue: any,
    cancelPartialreson: any,
    item: any,
    item_id: any,
    order_status: any
  ) => {

    const newrawdata = [
      {
        item_id: item_id,
        order_status: order_status,
        cancel_quantity: item.qty,
        reason_code: item.reason,
      },
    ];

    orderCancle.product_id = Object.assign({}, newrawdata);
    // console.log("line 297", orderCancle);
  };
  const cancelPartial = async (e: any, item: any) => {
    let apiEndpoint = baseUrlDefiner();
    setcancellingProduct(item.id)
    let payload = {
      "action": "partial_cancel",
      "order_id": orderCancle?.order_id,
      "order_status": orderState.order_state,
      "product_id": {
        "0": {
          "item_id": item.product_id,
          "order_status": orderState.order_state,
          "cancel_quantity": cancelQuantityl,
          "reason_code": reasonCode
        }
      }

    }

    // console.log(cancelQuantityl, reasonCode);

    if (cancelQuantityl <= item.quantity) {

      if (reasonCode !== "" && cancelQuantityl !== "") {
        // alert()
        try {

          const response = await axios({
            url: `${apiEndpoint}/sellerordercancel`,
            method: "POST",
            headers: {
              "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: payload,
          });
          if (response.status === 200) {
            if (response?.data?.status === 403) {
              toast.error(response?.data?.data);
              setcancellingProduct("");
              getorderDetails(order_id);
              setcancelQuantityl("")
              setreasonCode("")
              return
            }
            setcancellingProduct("");
            getorderDetails(order_id);
            setcancelQuantityl("")
            setreasonCode("")
            toast.success("Order cancel updated !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          } else {
            // console.log("response for cancelation", response);
            setcancellingProduct("")
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          console.error(" error 784", error);
          setcancellingProduct("")
          toast.error("Something went wrong !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        setcancellingProduct("")
        // alert("Please enter cancel quantity and reason for cancel")
        setshowEnterValueAlert(true)
        setTimeout(() => {
          setshowEnterValueAlert(false)
        }, 4000);
      }
    } else {
      setcancellingProduct("")
      setshowCancelQuantityGreaterthenOrderQuantity(true)
      setTimeout(() => {
        setshowCancelQuantityGreaterthenOrderQuantity(false)
      }, 4000);
      // alert("Cancel quantity price cannot be greater than Order Quantity")
    }
  };


  console.log("issueID", issueID)

  // if (_orderDetail.order_state !== "") {
  //     const index = orderStatus.indexOf(_orderDetail.order_state);
  //     if (index !== -1) {
  //       setorderStatus(orderStatus.slice(index));
  //     }
  //   }
  // console.log("orderDetails---->", _orderDetail);
  // console.log("orderStatus---->", orderStatus);
  const [cancelpopup, setcancelpopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState();
  const cancelbtn = () => {
    setcancelpopup(true);
    // console.log("cancelPopup");
  };

  const [cancelreson, setcancelreson] = useState("");
  const cancelReasonStatement = [
    {
      text: " Price of one or more items have",
      code: "001"
    },
    {
      text: "One or more items in the Order not available",
      code: "002"
    },
    {
      text: "Product available at lower than order price",
      code: "003"
    },
    {
      text: "Order in pending shipment / delivery state for too long",
      code: "004"
    },
    {
      text: "Merchant rejected the order",
      code: "005"
    },
    {
      text: "Order not shipped as per buyer app SLA",
      code: "006"
    },
    {
      text: "Order not ready for pickup",
      code: "008"
    },
    {
      text: "Wrong product delivered",
      code: "009"
    },
    {
      text: "Buyer wants to modify details",
      code: "010"
    },
    {
      text: "Buyer not found or cannot be contacted",
      code: "011"
    },
    {
      text: "Buyer does not want product any more",
      code: "012"
    },
    {
      text: "Buyer refused to accept delivery",
      code: "013"
    },
    {
      text: "Address not found",
      code: "014"
    },
    {
      text: "Buyer not available at location",
      code: "015"
    },
    {
      text: "Accident / rain / strike / vehicle issues",
      code: "016"
    },
    {
      text: "Order delivery delayed or not possible",
      code: "017"
    },
    {
      text: "Delivery pin code not serviceable",
      code: "018"
    },
    {
      text: "Pickup pin code not serviceable",
      code: "019"
    },
  ]

  const changeReturnStatus = (selectedStatus: any) => {
    // console.log("selectedStatus", selectedStatus);
    // istatus = selectedStatus;
    setcancelreson(selectedStatus);
  };

  const ordercancel = async () => {
    // console.log("cancel", cancelreson);
    // console.log("orderCancel hit");
    let apiEndpoint = NodebaseUrlDefiner();
    const statusData = {
      order_id: order_id,
      reason_code: cancelreson,
    };

    try {
      const response = await axios({
        method: "post",
        url: `${apiEndpoint}/v1/unsolicated_cancel`,
        headers: { "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}` },
        data: statusData,
      })
        .then(function (response) {
          // console.log("response 173", response);
          toast.success("Order Cancelled")
          setcancelpopup(false);
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.error("Something Went Wrong!");
        })
        .finally(function () {
          // console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
    }
  };

  // ---------------------------------- Code functions for logistics Start------------------------------------------------

  const [selectProvider, setselectProvider]: any = useState(null);
  const [providerPayload, setproviderPayload]: any = useState({
    transaction_id: "",
    message_id: ""
  })
  const [providersData, setprovidersData]: any = useState([])
  const [submitData, setSubmitData]: any = useState([])
  const controller = useRef(false)
  interface Item {
    id: number;
    checked: boolean;
  }
  const [issChecked, setIssChecked] = useState<Item[]>([]);
  const [isinitRunning, setisinitRunning] = useState(false);
  const [WeightSection, setWeightSection] = useState(false);

  const [wAndD, setwAndD]: any = useState({
    weight: '',
    weightUnit: "",
    length: '',
    width: '',
    height: '',
    dimensionUnit: "",
    delivery_type: ""
  })



  useEffect(() => {
    if (providerPayload.transaction_id !== "") {
      setTimeout(() => {
        getProviders()
      }, 3000);
    }

  }, [providerPayload])


  function WandChangeHandler(e: any) {
    setwAndD({ ...wAndD, [e.target.name]: e.target.value })
  }

  async function getProvidersPayload() {
    console.log("form data", wAndD)
    let apiEndpoint = baseUrlDefiner();
    let dataTosend = {
      order_id: order_id,
      delivery_type: wAndD.delivery_type,
      weight: {
        unit: wAndD.weightUnit,
        value: parseFloat(wAndD.weight)
      },
      dimensions: {
        length: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.length)
        },
        breadth: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.width)
        },
        height: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.height)
        }
      }
    }
    setselectProvider(false)
    try {
      const { data } = await axios({
        url: `${baseNodeapiUrl}/adapter/logistics/search`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: dataTosend
      })
      // console.log("search--->", data)
      if (data?.data) {
        const { transaction_id, message_id } = data.data.context
        setproviderPayload((prev: any) => {
          return { ...prev, transaction_id: transaction_id, message_id: message_id }
        })


      }
    } catch (err) {
      console.log("err----->", err);
      setselectProvider(null)
      toast.error("Please Try Again! Something Went Wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getProviders() {
    let apiEndpoint = baseUrlDefiner();
    const { data } = await axios({
      url: `${baseNodeapiUrl}/adapter/logistics/providers`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },
      data: providerPayload
    })

    setTimeout(() => {
      if (data.message == "flag1" && data?.data.length > 0) {
        setprovidersData(data.data)
        setselectProvider(true)
      } else {
        setselectProvider(null)
      }
    }, 3000);
  }

  async function getProgres_Status(transaction_id: any, message_id: any) {
    try {
      if (!transaction_id) {
        console.warn("Transaction ID is missing.");
        return;
      }
      setLoading(true);
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logistics/status/progress_status`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          transaction_id
        }
      });
      const trackDetails = data && data?.data ? data?.data : null;
      setTrackData(trackDetails);
      const status = data?.data?.fulfillments[0]?.state?.descriptor?.code;
      const stepIndex = steps.findIndex(step => step.toLowerCase() === status?.toLowerCase());
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
      }
      if (status && status.toLowerCase() === 'cancelled') {
        const updatedSteps = initialSteps.filter(step => step !== 'Agent-assigned' && step !== 'Order-picked-up' && step !== 'Out-for-delivery' && step !== 'Order-delivered');
        updatedSteps.push('Order-cancelled');
        const cancelledStepIndex = updatedSteps.findIndex(step => step.toLowerCase() === 'order-cancelled');
        if (cancelledStepIndex !== -1) {
          setActiveStep(cancelledStepIndex);
        }
        setSteps(updatedSteps);
      }
      setLoading(false);
    } catch (err) {
      console.log("some error--->", err)
    }
  }

  const statusTrack = async () => {
    try {
      setBtnLoading("statusTrackLoading", true);
      const dataPayload = {
        order_id: trackData?.order_id ?? null,
        transaction_id: trackData?.transaction_id ?? null,
        message_id: trackData?.message_id ?? null,
        bpp_id: trackData?.bpp_id ?? null,
        bpp_uri: trackData?.bpp_uri ?? null,
        bpp_descriptor_name: alldetails?.logistics?.[0]?.bpp_provider_id ?? null
      }
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logistics/track`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: dataPayload,
      });
      message.success(`Order Track Send successfully!`);
      setBtnLoading("statusTrackLoading", false);
      return data;
    } catch (err) {
      console.log("Track error--->", err)
    }
  }

  async function getTrackIssue(transaction_id: any) {
    try {
      if (!transaction_id) {
        return console.warn("Transaction ID is missing.");
      }
      setLoading(true);
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logisticsigm/v1/issue/data`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          transaction_id
        }
      });
      if (data?.data?.issue_id) {
        setIssueId(true);
        setOnIssueStatus(data?.data ? data?.data : "");
      } else {
        setIssueId(false);
      }
      // console.log("Resp", data?.data?.issue_id);
    } catch (err) {
      console.log("some error--->", err)
    }
  }
  const getTrackOnIssue_status = async () => {
    setTrackIssueStatus(true);
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logisticsigm/v1/issue_status`,
        // url: `${baseNodeapiUrl}/adapter/logisticsigm/v1/issue/status_data`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          issueId: _onIssueStatus?.issue_id,
          transactionId: _onIssueStatus?.transaction_id
        }
      })
      setTimeout(() => {
        OnTrackDetais(_onIssueStatus?.issue_id, _onIssueStatus?.transaction_id)
      }, 2000);
      handleModal('isModalIssueTrackVisible', 'show');
      setTrackIssueStatus(false);
      console.log("data----->", data)
    } catch (err) {
      console.log("error--->", err)
    }
  }
  async function OnTrackDetais(issueID: any, transaction_id: any) {
    setTrackIssueStatus(true)
    try {
      if (!transaction_id) {
        return console.warn("Transaction ID is missing.");
      }
      const { data } = await axios({
        method: "POST",
        // url: `${baseNodeapiUrl}/adapter/logisticsigm/v1/issue_status/data`,
        url: `${baseNodeapiUrl}/adapter/logisticsigm/v1/issue/status_data`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          issueId: issueID,
          transactionId: transaction_id
        }
      });
      setTrackIssueStatus(false)

      setTrackDetails(data?.data ? data?.data : "");
      console.log("On Issue_Status>>>>data", data);
    } catch (err) {
      console.log("some error--->", err)
    }
  }

  async function selectLogisticProvider(item: any, itemId: any) {
    let apiEndpoint = baseUrlDefiner();
    setSubmitData(item);
    setIssChecked((prevItems: any) =>
      prevItems.map((item: any) =>
        item.id === itemId ? { ...item, checked: true } : { ...item, checked: false }
      )
    );
  }

  const [isSelectingProviders, setisSelectingProviders] = useState(false);
  const [isConfirmingProviders, setisConfirmingProviders] = useState(false);

  async function submitSelectedProvider() {
    setisSelectingProviders(true)
    const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item, location } = submitData
    const { data } = await axios({
      url: `${baseNodeapiUrl}/adapter/logistics/init`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },
      data: {
        "order_id": order_id,
        "transaction_id": transaction_id,
        "message_id": message_id,
        "bpp_id": bpp_id,
        "bpp_uri": bpp_uri,
        "descripter": descripter,
        "bpp_provider_id": bpp_provider_id,
        "category_id": item.category_id,
        "items_id": item.id,
        "descriptor": item.descriptor.code,
        "fulfillment_id": item.fulfillment_id,
        "location": location == null ? '' : location[0].id

      }
    })

    if (data?.data?.message?.ack?.status == "ACK") {
      setTimeout(() => {
        setprovidersModalOpen(false)
        setisSelectingProviders(false)
        console.log("timer function")
        hide("key#1")
        setisConfirmingProviders(true)
      }, 2000);
    } else {
      setisSelectingProviders(false)
      alert("Init Failed")

    }


  }


  const [isConfirmingProvider, setisConfirmingProvider] = useState(false)
  const [isUpdatingProvider, setisUpdatingProvider] = useState(false)
  const onConfirmHandler = async (cdata: any) => {
    try {
      setisConfirmingProvider(true)
      const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item, bpp_descriptor_name } = submitData

      const { data } = await axios({
        url: `${baseNodeapiUrl}/adapter/logistics/confirm`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "transaction_id": transaction_id,
          "message_id": message_id,
          "bpp_id": bpp_id,
          "bpp_uri": bpp_uri,
          "order_id": order_id,
          "bpp_descriptor_name": bpp_descriptor_name
        }
      })

      if (data?.data?.message?.ack?.status == "ACK") {
        toast.success("Provider Confirmed !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setisConfirmingProvider(false)
        getorderDetails(order_id)
        // hide("key#2")
      } else {
        setisConfirmingProvider(false)
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setWeightSection(false)
    } catch (err) {
      setisConfirmingProvider(false);
      toast.error("Something went wrong !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("error---->", err)
    }
  }
  const updateLSP = async (cdata: any) => {
    try {
      setBtnLoading("deferUpdateLoading", true);
      const { data } = await axios({
        url: `${baseNodeapiUrl}/adapter/logistics/update`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id: alldetails?.logistics[0]?.order_id
        }
      })

      if (data?.data?.message?.ack?.status == "ACK") {
        setBtnLoading("deferUpdateLoading", false);
        setisUpdatingProvider(false)
        toast.success("Provider Confirmed !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // hide("key#2")
      } else {
        setisUpdatingProvider(false)
        setBtnLoading("deferUpdateLoading", false);
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setisUpdatingProvider(false)
      setBtnLoading("deferUpdateLoading", false);

      console.log("error---->", err)
    }
  }

  async function sendStatusHandler() {
    let apiEndpoint = NodebaseUrlDefiner();
    setsendingOrderStatus(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/v1/unsolicated_status`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "order_id": order_id
        }
      })

      if (data?.message?.ack?.status === "ACK") {
        toast.success("Unsolicated Status Updated");
        setsendingOrderStatus(false)
      } else {
        setsendingOrderStatus(false)
        toast.error("Error in Update!!")
      }

    } catch (err) {
      setsendingOrderStatus(false)
      toast.error("Something Went Wrong!!")
      console.log(err)
    }
  }
  const rawDataLbnp = {
    subCategory: '',
    shortDescription: '',
    longDescription: '',
    email: '',
    image: ''
  }
  const [lbnpIgm, setlbnpIgm] = useState(rawDataLbnp);

  const Sstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
  };
  const PSstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 2,
  };



  const lbnpHandler = (e: any) => {
    setlbnpIgm({ ...lbnpIgm, [e.target.name]: e.target.value });
  }

  const ImageHandler = async (e: any) => {
    try {
      // setLoadingImg(true)
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
        secretAccessKey: "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"
      })
      const params = {
        Bucket: "ondc-marketplace",
        Key: `image/${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type
      }
      let result = await s3.upload(params).promise();
      const { Location } = result;
      setlbnpIgm({ ...lbnpIgm, [e.target.name]: Location })
      // setLoadingImg(false)
    } catch (exception) {
      console.log(exception);
    }
  };
  const getShipmentStatus = async () => {
    setBtnLoading("shipmentStatusLoading", true);
    setTimeout(() => {
      setshipmentModalOpen(true)
      setBtnLoading("shipmentStatusLoading", false);
      // statusModal()
    }, 2000);
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logistics/status`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "buyer_order_id": order_id
        }
      })
      setBtnLoading("shipmentStatusLoading", false);
      getProgres_Status(transaction_data, "")
      // console.log("data----->", data)
      return data;
    } catch (err) {
      console.log("some error--->", err)
    }
  }


  const cancelProvider = async () => {
    setUpdating(true);
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseNodeapiUrl}/adapter/logistics/cancel`,
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id: alldetails?.logistics[0]?.order_id,
          transaction_id: alldetails?.logistics[0]?.transaction_id,
          message_id: alldetails?.logistics[0]?.message_id,
          bpp_id: alldetails?.logistics[0]?.bpp_id,
          bpp_uri: alldetails?.logistics[0]?.bpp_uri,
          bpp_descriptor_name: alldetails?.logistics[0]?.bpp_provider_id,
          cancellation_reason_id: reason_code
        }
      })
      if (data?.data?.error) {
        setisUpdatingProvider(false)
        toast.error(`${data?.data?.error?.message}`);
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setUpdating(false);
      handleOk();
    } catch (error) {
      console.error('API error:', error);
      setUpdating(false);
    }
  };

  const createLbnpIssue = async () => {
    setraiseIsseuLoading(true)
    try {
      if (alldetails?.logistics?.length) {
        const logistics = alldetails.logistics[0];
        const payload = {
          order_id: logistics.order_id,
          bpp_uri: logistics.bpp_uri,
          bpp_id: logistics.bpp_id,
          transaction_id: logistics.transaction_id,
          message_id: logistics.message_id,
          sub_category: lbnpIgm?.subCategory,
          short_desc: lbnpIgm?.shortDescription,
          long_desc: lbnpIgm?.longDescription,
          // images: lbnpIgm?.image,
          bpp_descriptor_name: logistics.bpp_provider_id,
        };
        const { data } = await axios.post(`${baseNodeapiUrl}/adapter/logisticsigm/v1/issue`, payload, {
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
        });
        console.log("Response data:", data);
        getTrackIssue(logistics?.transaction_id);
      }
      setraiseIsseuLoading(false);
      handleIssueOk();
      message.success(`Issue Created successfully!`);
    } catch (error) {
      toast.error("Something went wrong !");
      console.error("Error in creating issue:", error);
    }
  };


  function handleShipementModalClose() {
    setshipmentModalOpen(false)
  }
  function unsolicatedCancelReasonModalClose() {
    setunsolicatedCancelReasonModalOpen(false)
  }

  console.log("activeStep", activeStep)

  // ---------------------------------- Code functions for logistics End------------------------------------------------

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [invoiceHolder, setInvoiceHolder] = useState({ origin: "manual", path: "" })
  const PdfHandler = async (e: any) => {
    let apiEndpoint = baseUrlDefiner();
    try {
      const files = e.target.files;

      const uploadPromises = Array.from(files).map(async (file: any) => {
        let myFile = file.name.split(".");
        let myFileType = myFile[myFile.length - 1];

        const s3 = new aws.S3({
          accessKeyId: awsKeyId,
          secretAccessKey: awsSecretKey,
          region: awsRegion
        });

        const params = {
          Bucket: "ondc-marketplace",
          Key: `images/${uuidv4()}.${myFileType}`,
          Body: file,
          ContentType: file.type
        };

        let result = await s3.upload(params).promise();
        return result.Location;
      });

      const locations = await Promise.all(uploadPromises);
      let invoiceUrl = locations[0]
      setInvoiceHolder({ path: invoiceUrl, origin: "manual" })

    } catch (exception) {
      toast.error("Error in Adding Product Image");
      console.log(exception);
    }
  };

  async function saveInvoice() {
    let apiEndpoint = baseUrlDefiner();
    const { data } = await axios({
      url: `${apiEndpoint}/addInvoiceurl`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },
      data: {
        order_id,
        invoice: invoiceHolder.path
      }
    })

    if (data.status == 200) {
      toast.success("Invoice Saved!");
      setInvoiceHolder({
        path: invoiceHolder.path,
        origin: "database"
      })
    }
  }



  useEffect(() => {
    getorderDetails(order_id);
    window.scrollTo(0, 0);
  }, []);

  // console.log("ste____p", steps);

  return (
    <>
      <ThemeMaker>
        {!isLoading ? <div className="pl-4 pr-4 mb-2 mt-2">
          <div className="row rounded-lg">
            <div className={`_product_Details ${WeightSection ? 'col-md-8' : 'col-md-12'} col-sm-12 col-12 px-3`}>
              <div className="row justify-content-between overflow-hidden shadow">
                <div className="col-sm-6">
                  <div className="py-3" style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                    <span className="">Order ID:</span> {order_id}
                  </div>
                </div>
                <div className="col-md-6">
                  {alldetails?.logistics?.length === 0 &&
                    (decoded?.data?.user?.seller_id === 519 || decoded?.data?.user?.parent_id === 519 ? null : (
                      <div className="m-2 p-2 text-md-right">
                        <strong className="text-primary">Choose Logistics</strong>
                        <div className="my-2">
                          <input
                            type="radio"
                            name="logistics"
                            onChange={() => setWeightSection(false)}
                            style={{ width: "16px", height: "16px", marginRight: "8px" }}
                          />
                          <label>Off Network</label>
                        </div>
                        <div className="my-2">
                          <input
                            type="radio"
                            name="logistics"
                            onChange={() => setWeightSection(true)}
                            style={{ width: "16px", height: "16px", marginRight: "8px" }}
                          />
                          <label className="m-0">On Network</label>
                        </div>
                      </div>
                    ))}
                </div>
                {orderDetail &&
                  orderDetail.map((item: any, index: any) => (
                    <div className="container border-bottom py-3" key={index}>
                      <h6 className="text-info mb-3 ps-2">Item Details</h6>
                      <div className="row align-items-center">
                        <div className="col-md-4 text-center">
                          {/* <div><img src={orderDetail[0]?.image} className="rounded" alt="Image"
                          style={{ width: "150px", height: "150px", cursor: "pointer" }}
                          onClick={() => showModal(orderDetail[0]?.image)} />
                          <AntModal
                            title="Image Preview"
                            visible={isModalVisible}
                            onCancel={imagehandleCancel}
                            footer={null}
                          >
                            <img src={imageSrc} alt="Full Image" style={{ width: '100%', height: 'auto' }} />
                          </AntModal>
                        </div> */}
                          <img src={item?.image} alt="Product Image" className="img-fluid rounded w-75" />
                        </div>
                        <div className="col-md-8" style={{ fontSize: "14px", color: "black" }}>
                          <p className="mb-1"><strong>Product:</strong> {item?.product_name}</p>

                          {item?.category_name && (
                            <p className="mb-1"><strong>Category:</strong> {item?.category_name}</p>
                          )}

                          {item?.weight && (
                            <p className="mb-1"><strong>Size:</strong> {item?.weight}</p>
                          )}

                          {item?.quantity && (
                            <p className="mb-1"><strong>Qty:</strong> {item?.quantity}</p>
                          )}

                          {item?.cancel_qty && (
                            <p className="mb-1"><strong>Cancel Qty:</strong> {item?.cancel_qty}</p>
                          )}

                          {item?.price && (
                            <p className="mb-1"><strong>Price:</strong> {item?.price}</p>
                          )}
                          {item?.reason_code && (
                            <p className="mb-0">
                              <strong>Reason:</strong>
                              {` ${item.reason_code === "001"
                                ? "Price of one or more items have changed"
                                : item.reason_code === "002"
                                  ? "One or more items in the order not available"
                                  : item.reason_code === "003"
                                    ? "Product available at lower price than order"
                                    : item.reason_code === "004"
                                      ? "Order in pending shipment / delivery state too long"
                                      : item.reason_code === "005"
                                        ? "Merchant rejected the order"
                                        : item.reason_code === "006"
                                          ? "Order not shipped as per buyer app SLA"
                                          : item.reason_code === "008"
                                            ? "Order not ready for pickup"
                                            : item.reason_code === "009"
                                              ? "Wrong product delivered"
                                              : item.reason_code === "010"
                                                ? "Buyer wants to modify details"
                                                : item.reason_code === "011"
                                                  ? "Buyer not found or cannot be contacted"
                                                  : item.reason_code === "012"
                                                    ? "Buyer does not want product anymore"
                                                    : item.reason_code === "013"
                                                      ? "Buyer refused to accept delivery"
                                                      : item.reason_code === "014"
                                                        ? "Address not found"
                                                        : item.reason_code === "015"
                                                          ? "Buyer not available at location"
                                                          : item.reason_code === "016"
                                                            ? "Accident / rain / strike / vehicle issues"
                                                            : item.reason_code === "017"
                                                              ? "Order delivery delayed or not possible"
                                                              : item.reason_code === "018"
                                                                ? "Delivery pin code not serviceable"
                                                                : item.reason_code === "019"
                                                                  ? "Pickup pin code not serviceable"
                                                                  : "Unknown reason"
                                }`}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="col-md-6 col-sm-12 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="col-12 form-label genericlabel"
                  >
                    Order Status
                  </label>
                  <br />
                  <select
                    className="form-select form-control col-8"
                    aria-label="size 3 select example"
                    value={orderState?.order_state}
                    name="order_state"
                    onChange={(e) =>
                      setOrderState({
                        ...orderState,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    {orderStatus.map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      )
                    })}
                    {/* <option value="Created">Created</option>
                  <option value="Accepted">Accepted</option>
                  <option value="In-progress">In-progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option> */}
                    {/* <option value="Returned">Returned</option> */}
                  </select>
                </div>
                <div className="col-md-6 col-sm-12 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="col-12 form-label genericlabel"
                  >
                    Fulfillment Status
                  </label>
                  <br />
                  <select className="form-select form-control col-8" aria-label="size 3 select example" value={orderState?.fulfillment_state}
                    name="fulfillment_state"
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    }
                  >
                    <option value="">Select Fulfillment Status</option>
                    {(orderState?.order_state == "Created" || orderState?.order_state == "Accepted") && <> <option value="Pending">Pending</option> </>}

                    {(orderState?.order_state == "In-progress") && <>
                      <option value="Packed">Packed</option>
                      <option value="Order-picked-up">Order-picked-up</option>
                      <option value="Out-for-delivery">Out-for-delivery</option>

                    </>}


                    {(orderState?.order_state == "Completed") && <>
                      <option value="Order-delivered">Order-delivered</option>
                    </>}


                    {(orderState?.order_state == "Cancelled") && <>
                      <option value="RTO-Initiated">RTO-Initiated</option>
                      <option value="RTO-Disposed">RTO-Disposed</option>
                      <option value="RTO-Delivered">RTO-Delivered</option>
                    </>}
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="exampleFormControlInput1" className="col-12 form-label"
                  >
                    Tracking URL
                  </label>
                  <br />
                  <input className="form-control" type="text" placeholder="" value={orderState?.tracking_url} name="tracking_url" onChange={(e) => setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                  }
                  />
                </div>
                {showPop ? (
                  <>
                    <div
                      onClick={() => setShowPop(false)}
                      className={`cancelpopupoverlay`}
                    ></div>
                    <div className={`popup `}>
                      <p className="text-right p-2"> <CloseIcon role="button" onClick={() => setShowPop(false)} className="text-dark" />
                      </p>
                      <p className="text-center p-2" style={{ fontSize: "20px", fontWeight: "800", color: "black" }}>Partial Cancellation</p>

                      {orderDetail &&
                        orderDetail.map((item: any, index: any) => {
                          // console.log(item)
                          return (

                            <>
                              <div className="row p-4 mb-2" key={index}>
                                <div className="d-flex PMOrdeList" style={{ gap: "2px" }}>


                                  <div style={{ width: "200px", height: "200px" }}>
                                    <img src={item?.image} alt="" style={{ width: "100%", height: "100%" }} />
                                  </div>

                                  <div>
                                    <div className="col-12 mb-2">
                                      <span className="allkeys">Product Name:</span>  {item.product_name}
                                    </div>
                                    <div className="col-md-12 ">
                                      <span className="allkeys">Order Quantity:</span>   {item.quantity}
                                    </div>

                                    {(item.quantity == item?.cancel_qty) ? <div className="mt-2"> <span className="badge badge-danger">Product Cancelled</span> </div> : <>
                                      <div className="col-md-12">
                                        <label className="col-form-label allkeys" >
                                          Cancel Quantity:
                                        </label>
                                        <input
                                          type="number"
                                          style={{
                                            border: "none",
                                            borderBottom: "1px solid #ccc",
                                          }}
                                          name="qty"
                                          min={1}
                                          max={item.quantity}
                                          value={item.qty}
                                          onChange={(e) => {
                                            // console.log(e.target.value)
                                            // cancleQty(item, e.target.value);
                                            if (item.cancel_qty === "") {
                                              if ((parseInt(e.target.value) < 1)) {
                                                setshowGreaterValueAlert(true);
                                                setTimeout(() => setshowGreaterValueAlert(false), 3000)
                                                // setcancelQuantityl("");
                                                e.target.value = ""
                                              } else {
                                                setcancelQuantityl(e.target.value)
                                              }

                                            } else {
                                              if ((parseInt(e.target.value) > (parseInt(item.quantity)) - parseInt(item.cancel_qty)) || (parseInt(e.target.value) < 1)) {
                                                // alert("Value is greater");
                                                setshowGreaterValueAlert(true);
                                                setTimeout(() => setshowGreaterValueAlert(false), 3000)
                                                setcancelQuantityl("");
                                                e.target.value = ""
                                              } else {
                                                setcancelQuantityl(e.target.value)
                                              }
                                            }


                                          }}
                                        // onChange={PartialCancelChangeHandler}
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        {item?.cancel_qty && (
                                          <span className="allkeys" style={{ color: "red" }}>Cancelled Qty : {item?.cancel_qty}</span>
                                        )}
                                      </div>
                                      <div className="col-md-12" >
                                        <div>
                                          <label className="col-form-label allkeys">
                                            Cancel Reason:
                                          </label>
                                          <select className="col-md-6 text-left" name="reason" value={item.reason}

                                            onChange={(e) => {
                                              setreasonCode(e.target.value)
                                            }}
                                          >
                                            <option value="001">
                                              Select Cancle Reason
                                            </option>
                                            {
                                              cancelReasonStatement.map((item, index) => (
                                                <option value={item.code} key={index}>{item.text}</option>
                                              ))
                                            }
                                          </select>

                                        </div>
                                      </div>
                                      <div className="mt-2">
                                        <div className="col-6 justify-content-end position-relative p-2">
                                          <button
                                            className="btn btn-primary align-end"
                                            type="submit"
                                            onClick={(e) => cancelPartial(e, item)}
                                          >
                                            {
                                              cancellingProduct == item.id ? "Cancelling..." : "Cancel"
                                            }

                                          </button>
                                        </div>
                                      </div>
                                    </>}

                                  </div>
                                </div>
                              </div >
                              <hr className="hr" />
                            </>

                          );
                        })}

                      <div className="col-12 d-flex justify-content-end position-relative p-2">
                        <button
                          className="btn btn-primary align-end"
                          onClick={(e) => setShowPop(false)}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
                {order?.update ? <div className="row m-2 w-100 d-flex justify-content-between align-items-center ">
                  <div className=" col-md-6 col-sm-12">

                    {((orderState?.order_state === "Cancelled" && orderState?.fulfillment_state === "RTO-Initiated") || (orderState?.order_state === "Cancelled" && orderState?.fulfillment_state === "Cancelled")) ? <button className="btn btn-primary" onClick={(e) => setunsolicatedCancelReasonModalOpen(true)}>

                      Unsolicated Cancel
                    </button> : <button className="btn btn-primary" style={{ display: (fullUrl == "multipleselleradmin.thesellerapp.com" && decoded?.data?.user?.seller_id > 1) ? "none" : "inline-block" }} onClick={(e) => updateThisOrder(e)}>
                      Update
                    </button>}
                    <button className="btn btn-secondary ml-2 " onClick={sendStatusHandler} disabled={sendingOrderStatus} >
                      {sendingOrderStatus ? "Sending Staus..." : "Send Order Status"}
                    </button>
                    {/* 
                <button className="btn btn-secondary ms-2" onClick={sendStatusHandler} >
                  Send Status
                </button> */}
                    {/* <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => updateLSP(e)}
                >
                  Update LSP
                </button> */}
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="col-12 w-100 m-0 p-0 d-flex btnAlign">
                      {/* <button className="btn btn-primary" onClick={updateThisOrder}>
                  Update
                </button> */}

                      <div className="m-2 ">
                        <button onClick={cancelbtn} className="btn btn-outline-danger" style={{ display: (fullUrl == "multipleselleradmin.thesellerapp.com" && decoded?.data?.user?.seller_id > 1) ? "none" : "inline-block" }}  >
                          Order Cancel
                        </button>
                      </div>
                      {cancelpopup ? (
                        <>
                          <div onClick={() => setcancelpopup(false)}
                            className={`cancelpopupoverlay`}></div>
                          <div className={`cancelorderpopup p-3 `}>
                            <div className="row">
                              <div className="col-12 ">
                                <select className="btn btn-outline-dark w-100 text-left"
                                  value={cancelreson} onChange={(e) =>
                                    changeReturnStatus(e.target.value)
                                  }>
                                  <option value="">
                                    Select Cancel Reason
                                  </option>
                                  <option value="002">
                                    One or more items in the Order not available
                                  </option>
                                  <option value="005">
                                    Merchant rejected the order
                                  </option>
                                  {/* <option value="008">
                                Order not ready for pickup
                              </option> */}
                                  {/* <option value="011">
                                Buyer not found or cannot be contacted
                              </option> */}
                                  {/* <option value="013">
                                Buyer refused to accept delivery
                              </option> */}
                                  {/* <option value="014">Address not found</option> */}
                                  {/* <option value="015">
                                Buyer not available at location
                              </option> */}
                                  {/* <option value="016">
                                Accident / rain / strike / vehicle issues
                              </option> */}
                                  {/* <option value="017">
                                Order delivery delayed or not possible
                              </option> */}
                                  <option value="018">
                                    Delivery pin code not serviceable
                                  </option>
                                  <option value="019">
                                    Pickup pin code not serviceable
                                  </option>
                                  <option value="020">
                                    Order lost in transit
                                  </option>
                                </select>
                                <p className="text-center mt-3 d-none">{`You selected : ${cancelreson}`}</p>
                              </div>
                              <div className="col-12 m-2 p-2 d-flex justify-content-between">
                                <button
                                  className="btn btn-outline-dark col-5 col-md-5"
                                  onClick={() => setcancelpopup(false)}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn btn-outline-danger col-5 col-md-5"
                                  onClick={() => ordercancel()}
                                >
                                  Cancel Order
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="m-2 ">
                        <button
                          className="btn btn-primary"
                          style={{ display: (fullUrl == "multipleselleradmin.thesellerapp.com" && decoded?.data?.user?.seller_id > 1) ? "none" : "inline-block" }}
                          onClick={(e) => {
                            toggle(e);
                          }}
                        >
                          Partial Cancelation
                        </button>
                      </div>
                    </div>
                  </div>
                </div> : null}
              </div>

              {showPop && (
                <>
                  <div onClick={() => setShowPop(false)} className="cancelpopupoverlay"></div>
                  <div className="popup">
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <h5 className="text-center">Partial Cancellation</h5>
                      <CloseIcon role="button" onClick={() => setShowPop(false)} />
                    </div>
                    {orderDetail &&
                      orderDetail.map((item: any, index: any) => (
                        <div className="row p-3 mb-3" key={index}>
                          <div className="d-flex align-items-start">
                            <img src={item?.image} alt="" className="img-fluid" style={{ width: 100, height: 100 }} />
                            <div className="ms-3">
                              <p><strong>Product Name:</strong> {item.product_name}</p>
                              <p><strong>Order Quantity:</strong> {item.quantity}</p>
                              {item.quantity === item.cancel_qty ? (
                                <span className="badge bg-danger">Product Cancelled</span>
                              ) : (
                                <>
                                  <label>Cancel Quantity:</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={1}
                                    max={item.quantity}
                                    value={item.qty}
                                    onChange={(e) => {
                                      const value = parseInt(e.target.value);
                                      if (value < 1 || value > item.quantity - (item.cancel_qty || 0)) {
                                        setshowGreaterValueAlert(true);
                                        setTimeout(() => setshowGreaterValueAlert(false), 3000);
                                      } else {
                                        setcancelQuantityl(e.target.value);
                                      }
                                    }}
                                  />
                                  <p className="text-danger mt-1">Cancelled Qty: {item.cancel_qty}</p>
                                  <label>Cancel Reason:</label>
                                  <select
                                    className="form-select"
                                    name="reason"
                                    value={item.reason}
                                    onChange={(e) => setreasonCode(e.target.value)}
                                  >
                                    <option value="001">Select Cancel Reason</option>
                                    {cancelReasonStatement.map((reason, idx) => (
                                      <option key={idx} value={reason.code}>
                                        {reason.text}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    className="btn btn-primary mt-2"
                                    onClick={(e) => cancelPartial(e, item)}
                                  >
                                    {cancellingProduct === item.id ? "Cancelling..." : "Cancel"}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    <button className="btn btn-primary mt-3" onClick={() => setShowPop(false)}>
                      Done
                    </button>
                  </div>
                </>
              )}

              {/* ----------------------------------------HTML for Logistics Part Start ----------------------------------------------- */}
              {(decoded?.data?.user?.seller_id === 519 || decoded?.data?.user?.parent_id === 519 || !order?.update) ? null : (
                alldetails?.logistics?.length > 0 && (
                  <>
                    <h6 className="font-weight-bold text-primary text-left mt-2 mb-2">
                      Logistics Order Details
                    </h6>
                    <div className="row logistics_details__ overflow-hidden shadow">
                      <div className="col-md-6 col-sm-12 col-12 my-3">
                        <div className="">
                          <div className="container p-3 border rounded">
                            <h5 className="text-info mb-1 font-weight-bolder text-capitalize text-small">Order Details</h5>
                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Order Number</div>
                              <div className="col-6 text-right">{_orderDetail?.order_id}</div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Bill Number</div>
                              <div className="col-6 text-right">GST{_orderDetail?.order_id.slice(11, 17)}</div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Bill Date</div>
                              <div className="col-6 text-right">{_orderDetail?.billing_address?.created_at}</div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Delivery Charges</div>
                              <div className="col-6 text-right">
                                {(logisticCharges?.breakup && logisticCharges.breakup.length > 0) && logisticCharges.breakup[logisticCharges.breakup.length - 2]?.price?.value}
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Packing Charges</div>
                              <div className="col-6 text-right">
                                {(logisticCharges?.breakup && logisticCharges.breakup.length > 0) && logisticCharges.breakup[logisticCharges.breakup.length - 1]?.price?.value}
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-6 genericlabel">Total Amount</div>
                              <div className="col-6 text-right">{_orderDetail?.total_price}.00</div>
                            </div>

                            {_orderDetail?.return_amount !== 0 && (
                              <div className="row mb-2">
                                <div className="col-6 genericlabel">Cancel Return Amount</div>
                                <div className="col-6 text-right">-{_orderDetail?.return_amount}</div>
                              </div>
                            )}
                            <hr className="m-0" />
                            <div className="row text-info mb-4">
                              <div className="col-6 text-small">
                                <strong>Grand Total</strong>
                              </div>
                              <div className="col-6 text-right text-small">
                                <strong> ₹ {_orderDetail?.return_amount
                                  ? `${_orderDetail?.return_amt}`
                                  : `${_orderDetail?.total_price}`}.00
                                </strong>
                              </div>
                            </div>

                            <h5 className="text-info mb-1 font-weight-bolder text-capitalize text-small">Customer Details</h5>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Name</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.name}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Email</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.email}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Mobile Number</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.phone}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Shipping Address</div>
                              <div className="col-8 text-right">
                                {_orderDetail?.billing_address?.address?.building}, {_orderDetail?.billing_address?.address?.locality}
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">City</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.address?.city}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">State</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.address?.state}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Country</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.address?.country}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4 genericlabel">Pincode</div>
                              <div className="col-8 text-right">{_orderDetail?.billing_address?.address?.area_code}</div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-6 my-3 col-sm-12 col-12">
                        <div className="border d-flex justify-content-between p-2 rounded mb-2 text-right" style={{ gap: "10px" }}>
                          <div className="text-sm badge badge-pill">
                            Order by Deferred RTS
                          </div>
                          <button className="btn col-md-3 col-sm-12 col-12 btn-outline-primary btn-sm rounded text-uppercase font-weight-bold"
                            onClick={() => updateLSP(submitData)}
                          >
                            {loadingStates?.deferUpdateLoading ? (
                              <CircularProgress style={{ color: "black" }} size={20} />
                            ) : (
                              "Update "
                            )}
                          </button>
                        </div>
                        <div className="order-summary p-3 border rounded">
                          <div className="header d-flex justify-content-between align-items-center">
                            <div>
                              <h6 className="order-number my-1"><strong>Order Number </strong> : <span className="ms-2">{alldetails?.logistics[0]?.order_id}</span></h6>
                              <p className="order-details">Ordered On : {moment(alldetails?.logistics[0]?.createdAt).format('DD/MM/YYYY [at] h:mma')} <span className="font-weight-bold">|</span> Payment : Prepaid</p>
                            </div>
                            <span className="badge bg-primary text-gray-100">Accepted</span>
                          </div>
                          <div className="item-details mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="text-primary font-weight-bold">{alldetails?.logistics[0]?.logisticsNP}</h6>
                              {/* <span className="badge bg-gray-200 text-capitalize text-gray-900 toast-header"> */}
                              {/* {steps.map((label, index) => (
                                  index <= activeStep && (label)))
                                }</span> */}
                              <span className="badge bg-gray-200 text-capitalize text-gray-900 toast-header">
                                {steps?.filter((_, index) => index === activeStep)?.map((label, index) => (
                                  <span key={index} className="badge-item">{label}</span>
                                ))}
                              </span>
                            </div>
                            {/* <div className="badges mb-2 d-flex badge px-0" style={{ gap: "5px" }}>
                              <span className="badge bg-gray-200 font-weight-light rounded-pill text-capitalize text-gray-600">non returnable</span>
                              <span className="badge bg-gray-200 font-weight-light rounded-pill text-capitalize text-gray-600">non cancelable</span>
                            </div> */}
                            {/* <p>1 * Base Price</p>
                            <p>Offer (BUY2GET3): ₹0.00</p>
                            <h6>Total: ₹350.00</h6> */}
                          </div>
                          {_onIssueStatus?.issue_id ? (
                            <div className="charges mb-3">
                              <>
                                <div className="d-flex justify-content-between align-items-center text-gray-900">
                                  <h5 className="badge-danger badge font-weight-light rounded-pill text-capitalize">Issue Ticket No : </h5>
                                  <h5 className="font-weight-bolder text-capitalize text-small "> <span> {_onIssueStatus.issue_id ? _onIssueStatus.issue_id : 'N/A'}</span></h5>
                                </div>
                              </>
                            </div>
                          ) : ""}
                          <div className="order-total mb-3">
                            <div className="d-flex justify-content-between align-items-center text-gray-900">
                              <h5 className="font-weight-bolder text-capitalize text-small ">Order Total : </h5>
                              <h5 className="font-weight-bolder text-capitalize text-small "> <span>₹ {alldetails?.logistics[0]?.quote ? JSON.parse(alldetails?.logistics[0]?.quote)?.price?.value : 'N/A'}.00</span></h5>
                            </div>
                          </div>

                          <div className="action-buttons d-flex flex-wrap align-items-center" style={{ gap: "10px" }}>
                            {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && !issueID && (
                              <button
                                className="btn btn-outline-primary btn-sm flex-grow-1"
                                onClick={() => handleModal('isModalIssueVisible', 'show')}                          >
                                {raiseIsseuLoading ? <CircularProgress style={{ color: "black" }} size={20} /> : "Raise Issue"}
                              </button>
                            )}
                            {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && issueID && (
                              <button
                                className="btn btn-outline-primary btn-sm flex-grow-1"
                                onClick={getTrackOnIssue_status}
                              >
                                {trackIssueStatus ? <CircularProgress style={{ color: "black" }} size={20} /> : "Track Issue"}
                              </button>
                            )}
                            <button className="btn btn-outline-primary btn-sm flex-grow-1"
                              onClick={getShipmentStatus}>
                              {loadingStates.shipmentStatusLoading ? (
                                <CircularProgress style={{ color: "black" }} size={20} />
                              ) : (
                                "Get Status"
                              )}
                            </button>
                            <button className="btn btn-outline-primary btn-sm flex-grow-1"
                              onClick={statusTrack}>
                              {loadingStates.statusTrackLoading ? (
                                <CircularProgress style={{ color: "black" }} size={20} />
                              ) : (
                                "Track"
                              )}
                            </button>
                            <button className="btn btn-sm btn-outline-danger flex-grow-1" onClick={() => handleModal('orderCancelPopup', 'show')}>
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="m-2 p-3 border rounded shadow-sm">
                      <h5 className="text-info mb-3">Logistics Provider Details</h5>
                      <div className="mb-3">
                        <p>
                          <strong>Provider Name:</strong>
                          <span className="ms-2">{alldetails?.logistics[0]?.logisticsNP}</span>
                        </p>
                        <p>
                          <strong>Order Number:</strong>
                          <span className="ms-2">{alldetails?.logistics[0]?.order_id}</span>
                        </p>
                        <p>
                          <strong>Amount:</strong>
                          <span className="ms-2">{alldetails?.logistics[0]?.quote ? JSON.parse(alldetails?.logistics[0]?.quote)?.price?.value : 'N/A'} Rs</span>
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mt-4" style={{ gap: "10px" }}>
                        <button className="btn btn-outline-primary"
                          onClick={() => updateLSP(submitData)}
                          style={{ flex: 1 }}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => handleModal('orderCancelPopup', 'show')}
                          // onClick={() => setOrderCancelPopup(true)}
                          style={{ flex: 1, marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="mt-3 mb-3 d-flex flex-wrap align-items-center justify-content-start" style={{ gap: "10px" }}>
                        {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && !issueID && (
                          <button
                            className="btn btn-outline-primary d-flex align-items-center"
                            onClick={() => handleModal('isModalIssueVisible', 'show')}
                            style={{ flex: 1 }}
                          >
                            {raiseIsseuLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Raise Issue"}
                          </button>
                        )}

                        {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && issueID && (
                          <button
                            className="btn btn-outline-primary d-flex align-items-center"
                            onClick={getTrackOnIssue_status}
                            style={{ flex: 1 }}
                          >
                            {trackIssueStatus ? <CircularProgress style={{ color: "white" }} size={20} /> : "Track Issue"}
                          </button>
                        )}

                        <button
                          className="btn btn-outline-success d-flex align-items-center"
                          onClick={getShipmentStatus}
                          style={{ flex: 1 }}
                        >
                          {shipmentStatusLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Get Status"}
                        </button>

                        <button
                          className="btn btn-outline-warning d-flex align-items-center"
                          onClick={statusTrack}
                          style={{ flex: 1 }}
                        >
                          Track
                        </button>
                      </div>
                    </div> */}
                  </>


                )
              )}
              {/* <div className="row flex-column"> */}
              {/* <div className="col-12 mb-3 d-flex flex-wrap align-items-center justify-content-start" style={{ gap: "10px" }}>
                  {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && !issueID && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleModal('isModalIssueVisible', 'show')}
                      size="small"
                      className="d-flex align-items-center"
                    >
                      {raiseIsseuLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Raise Issue"}
                    </Button>
                  )}

                  {alldetails?.logistics?.length > 0 && alldetails.logistics[0]?.order_id && issueID && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getTrackOnIssue_status}
                      size="small"
                      className="d-flex align-items-center"
                    >
                      {trackIssueStatus ? <CircularProgress style={{ color: "white" }} size={20} /> : "Track Issue"}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="success"
                    onClick={getShipmentStatus}
                    size="small"
                    className="d-flex align-items-center"
                  >
                    {shipmentStatusLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Get Status"}
                  </Button>

                  <Button
                    variant="contained"
                    color="warning"
                    onClick={statusTrack}
                    size="small"
                    className="d-flex align-items-center"
                  >
                    Track
                  </Button>
                </div> */}

              {/* Progress Tracker Section (Optional) */}
              {/* Uncomment this section if you want to display the progress tracker */}
              {/* <div className="col-12 mt-3">
                {Loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ width: '100%', padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                      Progress Tracker
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: 3 }}>
                        {steps.map((label, index) => (
                          <Step key={index}>
                            <StepLabel>{`${index + 1}. ${label}`}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Stepper>

                  </Box>
                )}
              </div> */}
              {/* </div> */}

              {/* ----------------------------------------HTML for Logistics Part end ----------------------------------------------- */}


            </div>
            {WeightSection && (
              <div className="col-md-4 col-sm-12 col-12 px-0 pawan">
                <div className="card border shadow-sm mb-3 weight-dimensions-card">
                  <div className="card-header d-flex justify-content-around align-items-center border-0 bg-gradient-light">
                    <h5 className="m-0 font-weight-bold text-primary">Weight & Dimensions</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-label">Total Weight of Products:</label>
                      <div className="d-flex align-items-center mb-2" style={{ gap: "5px" }}>
                        <select className="form-select form-control me-2 w-50"
                          value={wAndD?.weightUnit}
                          name="weightUnit"
                          onChange={(e) => WandChangeHandler(e)}
                        >
                          <option value="">Select Unit</option>
                          <option value="gram">Gram</option>
                          <option value="kilogram">Kilogram</option>
                        </select>
                        <input
                          type="number"
                          className="form-control"
                          name="weight"
                          onChange={(e) => WandChangeHandler(e)}
                          placeholder="Weight"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Dimensions:</label>
                      <div className="d-flex align-items-center mb-2">
                        <select
                          name="dimensionUnit"
                          className="form-select form-control me-2 w-50"
                          value={wAndD?.dimensionUnit}
                          onChange={(e) => WandChangeHandler(e)}
                        >
                          <option value="">Select Unit</option>
                          <option value="meter">Meter</option>
                          <option value="centimeter">Centimeter</option>
                          <option value="inch">Inch</option>
                        </select>
                      </div>

                      <div className="d-flex flex-column mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label className="w-25">Length:</label>
                          <input
                            type="number"
                            className="form-control me-2"
                            name="length"
                            value={wAndD?.length}
                            onChange={(e) => WandChangeHandler(e)}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label className="w-25">Width:</label>
                          <input
                            type="number"
                            className="form-control me-2"
                            name="width"
                            value={wAndD?.width}
                            onChange={(e) => WandChangeHandler(e)}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label className="w-25">Height:</label>
                          <input
                            type="number"
                            className="form-control me-2"
                            name="height"
                            value={wAndD?.height}
                            onChange={(e) => WandChangeHandler(e)}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Select Delivery Type:</label>
                        <select
                          name="delivery_type"
                          className="form-select form-control"
                          value={wAndD?.delivery_type}
                          onChange={(e) => WandChangeHandler(e)}
                        >
                          <option value="">Select Delivery Type</option>
                          <option value="Express Delivery">Express Delivery</option>
                          <option value="Standard Delivery">Standard Delivery</option>
                          <option value="Immediate Delivery">Immediate Delivery</option>
                          <option value="Same Day Delivery">Same Day Delivery</option>
                          <option value="Next Day Delivery">Next Day Delivery</option>
                        </select>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center py-2 px-0 align-items-center" style={{ gap: "5px" }}>
                      <Pbutton
                        onClick={getProvidersPayload}
                        disabled={wAndD?.height === "" ? true : false}
                        className="btn btn-primary"
                      >
                        Search Providers
                      </Pbutton>
                      {selectProvider ? (
                        <Pbutton className="btn btn-primary btn-outline-warning" onClick={() => setprovidersModalOpen(true)}>
                          Get Providers
                        </Pbutton>
                      ) : (
                        selectProvider === false ? <BeatLoader /> : null
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "90vh" }}><CircularProgress /></div>}

        {/* Modals */}
        <>
          <Modal
            open={shipmentModalOpen}
            onClose={handleShipementModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box sx={{ width: '75%', maxWidth: '90vw', bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
              <div className="d-flex justify-content-end">
                <span style={{ cursor: "pointer" }} onClick={() => handleShipementModalClose()}><CloseIcon /></span>
              </div>
              <div className="col-12 col-md-12">
                {Loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ width: '100%', padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                      Progress Tracker
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => (
                        <Step key={index}>
                          <StepLabel>{`${index + 1}. ${label}`}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                )}
              </div>
            </Box>
          </Modal>
        </>

        <>
          <AntModal
            open={providersModalOpen}
            onCancel={() => setprovidersModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            width={800}
            footer={null} // Footer removed
          >
            {/* Title */}
            <h3 className="text-center mt-2" style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
              Available Providers
            </h3>
            <hr />
            <div className="providers-modal-content" style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>

              {/* Providers List */}
              <div
                className="w-100 d-flex flex-column align-items-center"
                style={{ gap: "15px", maxHeight: "400px", overflowY: "auto", padding: "10px 20px", width: "100%" }}
              >
                {providersData?.map((item: any, index: any) => {
                  if (index >= issChecked.length) {
                    setIssChecked((prevState) => [
                      ...prevState,
                      { id: index, checked: false }
                    ]);
                  }

                  return (
                    <ListContainer
                      key={index}
                      style={{
                        background: "#fff",
                        padding: "15px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        width: "100%",
                        transition: "all 0.3s ease",
                        cursor: "pointer",
                        border: issChecked[index]?.checked ? "1px solid #1890ff" : "1px solid #ddd",
                      }}
                    >
                      <div style={{ color: "#333", fontSize: "14px", fontWeight: "500" }}>
                        <p>Name: {item?.bpp_descriptor_name}</p>
                        <p>BPP URI: {item?.bpp_uri}</p>
                        <p>Category: {item.item.category_id}</p>
                        <p>Item ID: {item?.item?.id}</p>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ fontSize: "15px", fontWeight: "bold", color: "#1890ff" }}
                        >
                          Price: Rs {item.item.price.value}
                          <section>
                            <label
                              style={{
                                display: "inline-block",
                                marginLeft: "5px",
                                color: "#333",
                                fontWeight: "500"
                              }}
                            >
                              Select
                            </label>
                            <input
                              type="radio"
                              name="choose"
                              onChange={() => selectLogisticProvider(item, index)}
                              style={{
                                appearance: "none",
                                marginRight: "5px",
                                position: "relative",
                                top: "2px",
                                width: "14px",
                                height: "14px",
                                borderRadius: "50%",
                                border: "2px solid #1890ff",
                                backgroundColor: issChecked[index]?.checked ? "#1890ff" : "transparent",
                                outline: "none",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                                marginLeft: "8px"
                              }}
                            />
                          </section>
                        </div>
                      </div>
                    </ListContainer>
                  );
                })}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* Submit Button */}
                <button
                  onClick={submitSelectedProvider}
                  className="btn btn-primary col-md-3 col-sm-12 col-12 mt-4">
                  {isSelectingProviders ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}
                </button>
              </div>
            </div>
          </AntModal>
        </>
        <>
          <AntModal
            open={isConfirmingProviders}
            onCancel={() => setisConfirmingProviders(false)}
            footer={null}
            width={700}
            className="providers-modal"
            closable={false} // Disable the default close button
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Confirm Provider</h3>
                <CloseIcon className="close-icon" onClick={() => setisConfirmingProviders(false)} style={{ cursor: "pointer" }} />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <h5 className="modal-title text-primary">Provider Details</h5>
                </div>
                <div className="list-group">
                  <div className="list-group-item d-flex justify-content-between align-items-center border-bottom">
                    <span className="fw-bold">Provider Name:</span>
                    <span>{submitData?.descripter || "N/A"}</span>
                  </div>
                  <div className="list-group-item d-flex justify-content-between align-items-center border-bottom">
                    <span className="fw-bold">Category:</span>
                    <span>{submitData?.item?.category_id || "N/A"}</span>
                  </div>
                  <div className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="fw-bold">Price:</span>
                    <span>Rs. {submitData?.item?.price?.value || "0.00"}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => { onConfirmHandler(submitData) }}
                  className="btn btn-primary col-md-3 col-sm-12 col-12 mt-4">
                  {isConfirmingProvider ? (
                    <CircularProgress style={{ color: "white" }} size={15} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>

            </div>
          </AntModal>
        </>

        <>
          <Modal
            open={unsolicatedCancelReasonModalOpen}
            onClose={unsolicatedCancelReasonModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box sx={{ width: '25%', maxWidth: '90vw', bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
              <div className="d-flex justify-content-end">
                <span style={{ cursor: "pointer" }} onClick={() => unsolicatedCancelReasonModalClose()}><CloseIcon /></span>
              </div>
              <div className="col-12 col-md-12">
                <p>Select Cancellation Reason</p>
                <select className="form-control" onChange={(e) => setunsolicatedCancelReasonValue(e.target.value)} >
                  <option selected disabled >Sellect Reason  </option>
                  <option value="011">Buyer not found or cannot be contacted  </option>
                  <option value="012">Buyer does not want product any more </option>
                  <option value="013">Buyer refused to accept delivery   </option>
                  <option value="014">Address not found</option>
                  <option value="015">Buyer not available at location </option>
                </select>

                <div className="d-flex justify-content-end mt-2">
                  <button className="btn btn-success" disabled={unsolicatedCancelReasonValue === "" ? true : false} onClick={unsolicatedCancel} >Cancel</button>
                </div>
              </div>
            </Box>
          </Modal>
        </>

        <Snackbar open={showGreaterValueAlert} autoHideDuration={2000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Please fill correct quantity
          </Alert>
        </Snackbar>
        <Snackbar open={showEnterValueAlert} autoHideDuration={3000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Please enter cancel quantity and reason for cancel
          </Alert>
        </Snackbar>
        <Snackbar open={showCancelQuantityGreaterthenOrderQuantity} autoHideDuration={3000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Cancel Quantity Can't be grater then Order Quantity
          </Alert>
        </Snackbar>

        <AntModal
          open={modalVisibility?.orderCancelPopup}
          onOk={async () => { await cancelProvider(); }}
          onCancel={handleCancel}
          width={800}
          confirmLoading={updating}
          okText={updating ? 'Updating...' : 'Confirm'}
          cancelText="Cancel">
          <h6 className="m-0 font-weight-bold text-danger text-left">Cancel Provider</h6>
          <hr />
          <div className="text-center">
            <div className="selectStatus">
              <p>{`Select a Reason for cancellation : ${reason_code ? reason_code : ""}`}</p>
              <select
                className="btn btn-secondary"
                value={reason_code}
                onChange={(event: any) => {
                  setreason_code(event.target.value);
                }}>
                <option selected disabled>Select Reason Code</option>
                <option value="001">Price of one or more items have changed due to which buyer was asked to make additional payment</option>
                <option value="003">Product available at lower than order price</option>
                <option value="004">Store is not accepting order</option>
                <option value="007">Order / fulfillment not received as per buyer app TAT SLA</option>
                <option value="009">Wrong product delivered</option>
                <option value="010">Buyer wants to modify address / other order details</option>
              </select>
            </div>
          </div>
          <hr />
        </AntModal>

        <AntModal open={modalVisibility?.isModalIssueVisible}
          onOk={async () => {
            if (isChecked) {
              await createLbnpIssue();
              handleIssueOk();
            } else {
              alert('Please check the box to proceed.');
            }
          }}
          onCancel={handleIssueCancel}
          width={800}
          confirmLoading={updating}
          okText={updating ? "Updating..." : "Confirm"}
          cancelText="Cancel"
        >
          <h6 className="m-0 font-weight-bold text-left">Issue Board</h6>
          <hr />
          {alldetails?.logistics?.length > 0 && <><div className="d-flex align-items-center my-2 p-2  rounded">
            <div style={{ width: "80%" }}>
              <Button className="d-flex align-items-center w-100 my-1"
                id="">
                <div style={{ flexBasis: "20%" }}>
                  <div className="form-check">
                    <input
                      className="lg-checkbox"
                      type="checkbox"
                      id="flexCheckDefault"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                <div style={{ flexBasis: "80%" }}>
                  <p className="mb-0" style={{ fontSize: "16px", textAlign: "left" }} title={alldetails?.products[0]?.product_name}>
                    {alldetails?.products[0]?.product_name?.split(' ').slice(0, 4).join(' ')}
                  </p>
                  <div className="pt-1">
                    <p className="mb-0" style={{ textAlign: "left", fontSize: "14px" }}
                      title={alldetails?.products[0]?.quantity}>
                      QTY: {alldetails?.products[0]?.quantity}
                    </p>
                  </div>
                </div>
              </Button>
            </div>
            <div style={{ width: "30%" }} className="text-center">
              <p className="mb-0 font-weight-bold">{alldetails?.products[0]?.quantity}</p>
            </div>
            <div className="ms-auto">
              <p className="mb-0" style={{ whiteSpace: "nowrap" }}>
                ₹ {alldetails?.products?.reduce((sum: any, product: any) => sum + parseFloat(product.price), 0).toFixed(2)}
              </p>
            </div>
          </div>
            <Form layout="vertical">
              <Form.Item label="Select Issue Subcategory" required>
                <select className="form-control" name="subCategory" value={lbnpIgm?.subCategory}
                  onChange={(e) => lbnpHandler(e)} >
                  <option>Select Sub Category</option>
                  <option value="FLM02">Delay in Delivery</option>
                  <option value="FLM03">Delayed delivery</option>
                  <option value="FLM01">Wrong delivery address</option>
                </select>
              </Form.Item>
              <Form.Item label="Short Description" required>
                <Input value={lbnpIgm?.shortDescription}
                  name="shortDescription"
                  onChange={(e) => lbnpHandler(e)}
                  placeholder="Enter short description"
                  required
                />
              </Form.Item>
              <Form.Item label="Long Description" required>
                <Input value={lbnpIgm?.longDescription}
                  name="longDescription"
                  onChange={(e) => lbnpHandler(e)}
                  placeholder="Enter long description"
                  required
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={lbnpIgm?.email}
                  name="email"
                  onChange={(e) => lbnpHandler(e)}
                  placeholder="Enter Email"
                  required
                />
              </Form.Item>
              <Form.Item label="Images (Maximum 4)" >
                <input type="file"
                  className="form-control"
                  onChange={(e) => ImageHandler(e)}
                  accept="image/*"
                  name="image"
                  multiple
                  // onBlur={(e) => errorHandler(e)}
                  id="image"
                />
              </Form.Item>
            </Form></>}
          <hr />
        </AntModal>

        <AntModal
          open={modalVisibility?.isModalIssueTrackVisible}
          onOk={handleIssueTrackOk}
          onCancel={handleIssueTrackCancel}
          width={1000}
          confirmLoading={updating}
          okText={updating ? "Updating..." : "Close Issue"}
          cancelText="Cancel"
          okButtonProps={{
            style: {
              backgroundColor: updating ? '#1890ff' : '#ec3d3d',
              color: '#fff',
              borderColor: updating ? '#1890ff' : '#ec3d3d',
            },
            disabled: trackDetails?.message?.issue?.status === "CLOSED" || updating,
          }}
        >
          <h6 className="m-0 font-weight-bold text-left ">Track Issue ID Details</h6>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
            <div className="">
              {/* <img src="https://rajnigandha.com/dsg/product_image/1702630157-1.png" alt="TVS Power bank"
                style={{ width: '150px', height: 'auto', objectFit: "fill", borderRadius: '8px' }} /> */}
            </div>
            <div style={{ marginLeft: '20px' }}>
              {/* <h3>TVS Power Bank</h3> */}
              {/* <p>Shiva Road, A-22 Rohini Sector 7, North West Delhi district, New Delhi, Delhi, IND</p> */}
              <div>
                {/* <strong>FULFILLMENT:</strong> <span className="text-danger">Delay in delivery</span>/ */}
              </div>
              <div>
                {trackDetails?.message?.issue?.description?.short_desc && (
                  <>
                    <strong>Description :</strong>
                    <span className="text-danger">
                      {trackDetails?.message?.issue?.description?.short_desc}
                    </span>
                  </>
                )}
              </div>
              <div>
                <p className="m-0"><strong>Transaction Id : </strong><span style={{ color: '#595959' }}>{trackDetails?.context?.transaction_id}</span></p>
                <p className="m-0"><strong>Issue Id : </strong><span style={{ color: '#595959' }}>{trackDetails?.message?.issue?.id}</span></p>
                {/* <p>Issue Raised On: <span style={{ color: '#595959' }}>01 October 2024 at 05:56pm</span></p> */}
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <p className={`btn ${trackDetails?.message?.issue?.status === "CLOSED" ? "btn-outline-danger" : "btn-outline-success"}`}
                style={{ fontSize: '16px' }}>
                {trackDetails?.message?.issue?.status || ""}
              </p>
            </div>
          </div>
          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <button type="button" className="text-primary form-control" disabled>View Summary</button>
          </div>
          <hr />
        </AntModal>
      </ThemeMaker >
    </>
  );
};

export default LogisticsCreateOrder;
