import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker';
import { v4 as uuidv4 } from 'uuid';
import aws from 'aws-sdk';
import { config } from '../../utils/config';
import { removeExifData } from '../NewmasterPage/helper';
import { baseUrlDefiner } from '../../utils/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const { awsKeyId, awsSecretKey, awsRegion } = config;

export default function Createcustomization() {
    const params = useParams();
    const [formState, setformState] = useState({
        menu_name: "",
        custom_image: "",
        custom_group: [
            {
                "name": "",
                "config_min": 1,
                "config_max": 1,
                "config_seq": 1,
                "customizations": [
                    {
                        "name": "",
                        "mrp": "",
                        "quantity": "",
                        "is_countable_item": "",
                        "min_price": "",
                        "max_price": "",
                        "tax": ""
                    },
                    {
                        "name": "",
                        "mrp": "",
                        "quantity": "",
                        "is_countable_item": "",
                        "min_price": "",
                        "max_price": "",
                        "tax": ""
                    }
                ]
            },

        ]

    })

    useEffect(() => {
        if (params?.id) {
            getFormData()
        }
    }, [])

    async function getFormData() {
        const apiEndpoint = baseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/getproduct_customizations`,
            method: "POST",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: {
                "group_id": params.id
            }
        });

        if (data?.status === 200) {
            let d = data.data
            console.log("dddddddddddddd", d)
            // setproductList(data?.data);
            setformState({
                group_id: d[0]?.group_id,
                menu_name: d[0]?.menu_name,
                custom_image: d[0]?.custom_image,
                custom_group: [
                    {
                        "name": d[0]?.grouping_name,
                        "config_min": d[0]?.config_min,
                        "config_max": d[0]?.config_max,
                        "config_seq": d[0]?.config_seq,
                        "customizations": d.map(item => {
                            return {
                                "id": item?.id,
                                "name": item?.customization_name,
                                "mrp": item?.mrp,
                                "quantity": item?.quantity,
                                "is_countable_item": item?.is_countable_item,
                                "min_price": item?.min_price,
                                "max_price": item?.max_price,
                                "tax": item?.tax

                            }
                        })
                    },

                ]

            })
        } else {
            toast.error("Something went wrong!")
        }

        console.log("data------", data)
    }

    console.log("formState-------", formState)

    function increseLineHandler() {
        let z = { ...formState };
        z.custom_group[0]?.customizations.push({
            "name": "",
            "mrp": "",
            "quantity": "",
            "is_countable_item": false,
            "min_price": "",
            "max_price": "",
            "tax": 1
        })

        setformState(z)

    }

    function ontextChangeHandler(e, index) {
        let { name, value } = e.target;
        let z = { ...formState };
        z.custom_group[0].customizations[index] = { ...z.custom_group[0]?.customizations[index], [name]: value };
        setformState(z)
    }
    function onCheckChangeHandler(e, index) {
        let { name, checked } = e.target;
        let z = { ...formState };
        z.custom_group[0].customizations[index] = { ...z.custom_group[0]?.customizations[index], [name]: checked };
        setformState(z)
    }

    function onFormChangeHandler(e) {
        let { name, value } = e.target;
        if (name === "name") {
            setformState(prev => {
                return {
                    ...prev,
                    custom_group: [{ ...prev.custom_group[0], name: value }]
                };
            });
        } else {
            setformState(prev => {
                return { ...prev, [name]: value };
            });
        }
    }

    async function onSubmitHandler(operation) {
        console.log("formState------", formState);
        let endpoint;
        if (operation === "update") {
            endpoint = "updategroupcustomization"
        }
        if (operation === "create") {
            endpoint = "customizations"
        }
        const apiEndpoint = baseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/${endpoint}`,
            method: "POST",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: {
                "product_customization": [{ ...formState }]
            }
        });

        if (data?.status === 200) {
            toast.success("Customization Added!")
        }

        console.log("data------", data)
    }
    async function deleteCustomizationHandler(id) {
        if (!id) {
            toast.error("No id is present for this customization.")
            return
        }
        try {
            const apiEndpoint = baseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/deletecustomization`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "customization_id": [id]
                }
            });

            if (data?.status === 200) {
                toast.error("Customization Deleted!");
                getFormData()
            }else{
                toast.error("Error in deleting!")
            }

            console.log("data------", data)
        } catch (err) {
            toast.error("Internal server error!")
        }
    }

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];

            // Remove the Exif metadata using the custom function
            const modifiedArrayBuffer = await removeExifData(file);
            // Convert the modified data back to a Blob
            const strippedBlob = new Blob([modifiedArrayBuffer], { type: file.type });

            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
            });

            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: strippedBlob, // Use the stripped file
                ContentType: file?.type
            };

            let result = await s3.upload(params).promise();
            const { Location } = result;
            setformState(prev => {
                return { ...prev, custom_image: Location };
            });


        } catch (exception) {
            console.error('Error during image upload:', exception);
        }
    };
    return (
        <ThemeMaker>

            <div className="row w-100 m-0 mt-2">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Add Customization
                            </h6>

                            <Link to="/customizationlist">    <span style={{ color: "blue", textDecoration: "underline", fontSize: "12px" }}>Customizations</span> </Link>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className='genericlabel'>Menu Name </label>
                                        <input type='text' className='form-control' value={formState?.menu_name} name='menu_name' onChange={onFormChangeHandler} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className='genericlabel'>Image </label> {formState?.custom_image && <a href={formState?.custom_image} target='_blank'> View Image</a>}

                                        <input type='file' className='form-control' name='custom_image' onChange={productImageHandler} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className='genericlabel'>Group Name </label>
                                        <input type='text' className='form-control' value={formState.custom_group[0]?.name} name='name' onChange={onFormChangeHandler} />
                                    </div>
                                </div>
                            </div>

                            <div className='row'>

                                <table>
                                    <thead>
                                        <td className='genericlabel'>Name</td>
                                        <td className='genericlabel'>MRP</td>
                                        <td className='genericlabel'>Quantity</td>

                                        <td className='genericlabel'>Min Price</td>
                                        <td className='genericlabel'>Max Price</td>
                                        <td className='genericlabel'>Tax</td>
                                        <td className='genericlabel'>Is Countable</td>
                                        <td className='genericlabel'>Action</td>
                                    </thead>

                                    <tbody>
                                        {formState.custom_group[0]?.customizations?.map((item, index) => {
                                            {/* console.log("item------", item) */ }
                                            return <tr key={item.id}>
                                                <td> <input type="text" name='name' className='form-control' value={item.name} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>
                                                <td> <input type="text" name='mrp' className='form-control' value={item.mrp} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>
                                                <td> <input type="text" name="quantity" className='form-control' value={item.quantity} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>

                                                <td> <input type="text" name='min_price' className='form-control' value={item.min_price} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>
                                                <td> <input type="text" name='max_price' className='form-control' value={item.max_price} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>
                                                <td> <input type="text" name='tax' className='form-control' value={item.tax} onChange={(e) => { ontextChangeHandler(e, index) }} /> </td>
                                                <td> <input type="checkbox" name="is_countable_item" checked={item.is_countable_item} onChange={(e) => { onCheckChangeHandler(e, index) }} /> </td>
                                                <td> <button className='btn btn-sm btn-danger' onClick={() => {
                                                    deleteCustomizationHandler(item?.id)
                                                }}>Delete</button> </td>
                                            </tr>
                                        })}
                                    </tbody>

                                </table>
                                <div className='d-flex justify-content-end w-100'>
                                    <span style={{ color: "blue", textDecoration: "underline", fontSize: "12px" }} onClick={increseLineHandler} >Add Item</span>
                                </div>

                            </div>
                            <div className='row justify-content-end mt-5'>
                                {params.id ? <button className='btn btn-success' onClick={() => { onSubmitHandler("update") }}>Update</button> : <button className='btn btn-success' onClick={() => { onSubmitHandler("create") }}>Submit</button>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <h2>Createcustomizations</h2> */}
        </ThemeMaker>
    )
}
