import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../../components/thememaker'
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../../utils/config';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
// import { config } from '../../utils/config';
import { config } from '../../../utils/config';


const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require("aws-sdk");

export default function Createitem() {

    const params = useParams();
    const { id } = params;

    const [formData, setFormData] = useState({
        category_id: "",
        name: "",
        short_desc: "",
        status: true,
        provider_id: "",
        principal: "",
        interest: "",
        net_disbursed_amount: "",
        Minimum_Interest_Rate: "",
        Maximum_Interest_Rate: "",
        Interest_Rate: "",
        Loan_Term: "",
        Interest_rate_type: "",
        Application_Fees: "",
        Foreclosure_Penalty: "",
        Interest_rate_conversion_charges: "",
        Delayed_payments_penalty: "",
        Other_Penal_Charges: "",
        Annual_Percentage_Rate: "",
        Repayment_Frequency: "",
        Terms_and_Conditions: "",
        Cool_off_period: "",
        Number_of_installments_of_repayment: "",
        Installment_amount: "",
        Maximum_Loan_Amount: "",
        Minimum_Loan_Amount: "",
        Maximum_Tenure: "",
        Minimum_Tenure: ""
    });

    useEffect(() => {
        if (id) {
            getSellerData()
        }
    }, [])

    async function getSellerData() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/getitembyID/${id}`,
                method: "GET",

            })
            console.log(data)
            let responseValues = data?.data?.itemdata;
            setFormData({ ...responseValues })

            console.log(data)
        } catch (err) {
            console.log(err)
            toast.error("Error fetching sellers!")
        }

    }

    // onChangeHandler to update formData
    const onChangeHandler = (e) => {
        const { name, value } = e.target; // Destructure the target to get the name and value

        // Handle checkbox inputs (like 'status' if it was a checkbox)
        if (name === 'status') {
            setFormData(prevState => ({
                ...prevState,
                [name]: e.target.checked // if it's a checkbox, use checked instead of value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value // update the formData with new value
            }));
        }
    };

    async function onSubmitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        let url;
        console.log(id)
        if (id !== undefined) {
            url = `${apiEndpoint}/adminCredit/updateitem`
        } else {
            url = `${apiEndpoint}/adminCredit/createitem`
        }
        try {

            const payload = { ...formData }
            const { data } = await axios({
                method: "POST",
                url: url,
                data: payload
            })
            if (data.status === 200) {
                toast.success("Data inserted successfully!")
            }
            console.log("data----------", data)
        } catch (err) {
            console.log(err)
            toast.error("Somthing went wrong, please try later!")
        }
    }


    const productImageHandler = async (e, index) => {

        try {
            const files = e.target.files;

            const uploadPromises = Array.from(files).map(async (file) => {
                let myFile = file.name.split(".");
                let myFileType = myFile[myFile.length - 1];

                const s3 = new aws.S3({
                    accessKeyId: awsKeyId,
                    secretAccessKey: awsSecretKey,
                    region: awsRegion
                });

                const params = {
                    Bucket: "ondc-marketplace",
                    Key: `images/${uuidv4()}.${myFileType}`,
                    Body: file,
                    ContentType: file.type
                };

                let result = await s3.upload(params).promise();
                return result.Location;
            });

            const locations = await Promise.all(uploadPromises);
            // Update state with all image locations
            setFormData((prevProduct) => ({
                ...prevProduct,
                [e.target.name]: locations.toString()
            }));
        } catch (exception) {
            toast.error("Error in Adding Product Image");
            console.log(exception);
        }
    };




    return (
        <>

            <ThemeMaker>
                <div className="row m-0 w-100 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                                    Add Item
                                </h6>
                                <Link to="/finance/itemlist"><span style={{ fontSize: "14px", textDecoration: "underline", color: "blue" }}>All Items</span></Link>
                            </div>
                            <div className="card-body">
                                <div className='row p-2'>
                                    <div className="row">

                                        {/* Loan Category ID */}
                                        <div className="col-md-6 d-none">
                                            <div className="form-group">
                                                <label className="genericlabel">Category ID</label>
                                                <input onChange={onChangeHandler} value={formData?.category_id} className="form-control" type="text" name="category_id" />
                                            </div>
                                        </div>

                                        {/* Loan Name */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Loan Name</label>
                                                <input onChange={onChangeHandler} value={formData?.name} className="form-control" type="text" name="name" />
                                            </div>
                                        </div>

                                        {/* Short Description */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Short Description</label>
                                                <input onChange={onChangeHandler} value={formData?.short_desc} className="form-control" type="text" name="short_desc" />
                                            </div>
                                        </div>

                                        {/* Loan Status */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Loan Status</label>
                                                <input onChange={onChangeHandler} value={formData?.status ? "Active" : "Inactive"} className="form-control" type="text" name="status" readOnly />
                                            </div>
                                        </div>

                                        {/* Provider ID */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Provider ID</label>
                                                <input onChange={onChangeHandler} value={formData?.provider_id} className="form-control" type="text" name="provider_id" />
                                            </div>
                                        </div>

                                        {/* Principal Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Principal Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.principal} className="form-control" type="text" name="principal" />
                                            </div>
                                        </div>

                                        {/* Interest Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Interest Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.interest} className="form-control" type="text" name="interest" />
                                            </div>
                                        </div>

                                        {/* Net Disbursed Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Net Disbursed Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.net_disbursed_amount} className="form-control" type="text" name="net_disbursed_amount" />
                                            </div>
                                        </div>

                                        {/* Minimum Interest Rate */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Minimum Interest Rate (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Minimum_Interest_Rate} className="form-control" type="text" name="Minimum_Interest_Rate" />
                                            </div>
                                        </div>

                                        {/* Maximum Interest Rate */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Maximum Interest Rate (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Maximum_Interest_Rate} className="form-control" type="text" name="Maximum_Interest_Rate" />
                                            </div>
                                        </div>

                                        {/* Interest Rate */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Interest Rate (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Interest_Rate} className="form-control" type="text" name="Interest_Rate" />
                                            </div>
                                        </div>

                                        {/* Loan Term */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Loan Term (months)</label>
                                                <input onChange={onChangeHandler} value={formData?.Loan_Term} className="form-control" type="text" name="Loan_Term" />
                                            </div>
                                        </div>

                                        {/* Interest Rate Type */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Interest Rate Type</label>
                                                <input onChange={onChangeHandler} value={formData?.Interest_rate_type} className="form-control" type="text" name="Interest_rate_type" />
                                            </div>
                                        </div>

                                        {/* Application Fees */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Application Fees (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.Application_Fees} className="form-control" type="text" name="Application_Fees" />
                                            </div>
                                        </div>

                                        {/* Foreclosure Penalty */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Foreclosure Penalty (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Foreclosure_Penalty} className="form-control" type="text" name="Foreclosure_Penalty" />
                                            </div>
                                        </div>

                                        {/* Interest Rate Conversion Charges */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Interest Rate Conversion Charges (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.Interest_rate_conversion_charges} className="form-control" type="text" name="Interest_rate_conversion_charges" />
                                            </div>
                                        </div>

                                        {/* Delayed Payments Penalty */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Delayed Payments Penalty (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Delayed_payments_penalty} className="form-control" type="text" name="Delayed_payments_penalty" />
                                            </div>
                                        </div>

                                        {/* Other Penal Charges */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Other Penal Charges (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Other_Penal_Charges} className="form-control" type="text" name="Other_Penal_Charges" />
                                            </div>
                                        </div>

                                        {/* Annual Percentage Rate */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Annual Percentage Rate (%)</label>
                                                <input onChange={onChangeHandler} value={formData?.Annual_Percentage_Rate} className="form-control" type="text" name="Annual_Percentage_Rate" />
                                            </div>
                                        </div>

                                        {/* Repayment Frequency */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Repayment Frequency</label>
                                                <input onChange={onChangeHandler} value={formData?.Repayment_Frequency} className="form-control" type="text" name="Repayment_Frequency" />
                                            </div>
                                        </div>

                                        {/* Terms and Conditions */}
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="genericlabel">Terms and Conditions</label>
                                                <a href={formData?.Terms_and_Conditions} target="_blank" rel="noopener noreferrer">{formData?.Terms_and_Conditions}</a>
                                                <input type="file" />
                                            </div>
                                        </div> */}

                                        <div class="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Terms and Conditions</label> 
                                                {formData.Terms_and_Conditions &&  <a href={formData?.Terms_and_Conditions} target="_blank" rel="noopener noreferrer" style={{marginLeft: "10px"}}>Check File</a>}
                                                <input class="form-control" type="file" id="formFileMultiple" name="Terms_and_Conditions" multiple onChange={productImageHandler} />
                                            </div>
                                        </div>

                                        {/* Cool-off Period */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Cool-off Period</label>
                                                <input onChange={onChangeHandler} value={formData?.Cool_off_period} className="form-control" type="text" name="Cool_off_period" />
                                            </div>
                                        </div>

                                        {/* Number of Installments */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Number of Installments</label>
                                                <input onChange={onChangeHandler} value={formData?.Number_of_installments_of_repayment} className="form-control" type="text" name="Number_of_installments_of_repayment" />
                                            </div>
                                        </div>

                                        {/* Installment Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Installment Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.Installment_amount} className="form-control" type="text" name="Installment_amount" />
                                            </div>
                                        </div>

                                        {/* Maximum Loan Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Maximum Loan Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.Maximum_Loan_Amount} className="form-control" type="text" name="Maximum_Loan_Amount" />
                                            </div>
                                        </div>

                                        {/* Minimum Loan Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Minimum Loan Amount (INR)</label>
                                                <input onChange={onChangeHandler} value={formData?.Minimum_Loan_Amount} className="form-control" type="text" name="Minimum_Loan_Amount" />
                                            </div>
                                        </div>

                                        {/* Maximum Tenure */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Maximum Tenure</label>
                                                <input onChange={onChangeHandler} value={formData?.Maximum_Tenure} className="form-control" type="text" name="Maximum_Tenure" />
                                            </div>
                                        </div>

                                        {/* Minimum Tenure */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="genericlabel">Minimum Tenure</label>
                                                <input onChange={onChangeHandler} value={formData?.Minimum_Tenure} className="form-control" type="text" name="Minimum_Tenure" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='d-flex justify-content-end col-md-12'>
                                    <button className='btn btn-success' onClick={onSubmitHandler}>Submit</button>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </ThemeMaker>


        </>
    )
}
