import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { NodebaseUrlDefiner } from '../../../utils/config';
import { useParams } from "react-router-dom";
import { config } from '../../../utils/config';
import { v4 as uuidv4 } from "uuid";
const aws = require("aws-sdk");
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;

export default function Loankyc() {
    const params = useParams()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const transaction_id = urlParams?.get('transaction_id');
    const message_id = urlParams?.get('message_id');

    const [isSubmitting, setisSubmitting] = useState(false)
    const [form1State, setForm1State] = useState({
        formData: {
            "form_id": params?.id,
            "PAN": "",
            "city": "",
            "state": "",
            "addresProof": "",
            "address": "",
            "bankStatements": "",
            "pincode": "",
            "country": "",
            "street": "",
            "countryCode": "",
            "incomeProof": "",


        }
    });

    function onChangeHandler(e) {
        setForm1State(prev => ({
            ...prev,
            formData: { ...prev.formData, [e.target.name]: e.target.value }
        }));
    }


    async function submitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        function checkFormData(data) {
            for (const key in data) {
                // let notrewuiredKeys = ["official_email", "company_name", "addresProof", "addresProofL2",]
                // if (notrewuiredKeys.some(item => item === key)) {
                //     continue;
                // }
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    return false;
                }
            }
            return true;
        }

        if (checkFormData(form1State.formData)) {
            try {
                setisSubmitting(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/credit/selectForm`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        ...form1State.formData, transaction_id, message_id
                    }
                });

                if (data.status === 200) {
                    toast.success("Form Submitted Successfully.");
                    setForm1State({
                        formData: {
                            "form_id": "",
                            "PAN": "",
                            "city": "",
                            "state": "",
                            "addresProof": "",
                            "address": "",
                            "bankStatements": "",
                            "pincode": "",
                            "country": "",
                            "street": "",
                            "countryCode": "",
                            "incomeProof": "",               
                
                        }
                    })
                } else {
                    toast.error("Error In Submitting Form.");
                }
            } catch (err) {
                toast.error("Server not working, Please try again later!");
            } finally {
                setisSubmitting(false); // Ensure this is called in all cases
            }
        } else {
            toast.error("Please fill all fields!");
        }
    }

    const productImageHandler = async (e, index) => {
        try {
            const files = e.target.files;

            const uploadPromises = Array.from(files).map(async (file) => {
                let myFile = file.name.split(".");
                let myFileType = myFile[myFile.length - 1];

                const s3 = new aws.S3({
                    accessKeyId: awsKeyId,
                    secretAccessKey: awsSecretKey,
                    region: awsRegion
                });

                const params = {
                    Bucket: "ondc-marketplace",
                    Key: `images/${uuidv4()}.${myFileType}`,
                    Body: file,
                    ContentType: file.type
                };

                let result = await s3.upload(params).promise();
                return result.Location;
            });

            const locations = await Promise.all(uploadPromises);
            // Update state with all image locations
            setForm1State((prevProduct) => ({
                ...prevProduct, formData: { ...form1State?.formData, image: locations.toString() }

            }));
        } catch (exception) {
            toast.error("Error in Adding Product Image");
            console.log(exception);
        }
    };

    console.log(form1State?.formData);

    return (
        <div className="row w-100 m-0 mt-1">
            <div className="col-md-12 ">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-company_name-bold text-primary text-left d-flex justify-content-between align-items-center">
                            KYC Form
                        </h6>
                    </div>

                    <div className="card-body" style={{ minHeight: "90vh" }}>
                        <div className="row p-0 m-0">
                            {/* addresProof Proof */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Address Proof</label>
                                    <input
                                        type="text"
                                        name="addresProof"
                                        value={form1State.formData.addresProof}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* PAN */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>PAN</label>
                                    <input
                                        type="text"
                                        name="PAN"
                                        value={form1State.formData.PAN}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* Income Proof */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Income Proof</label>
                                    <input
                                        type="text"
                                        name="incomeProof"
                                        value={form1State.formData.incomeProof}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* Bank Statements */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Bank Statements</label>
                                    <input
                                        type="file"
                                        name="bankStatements"
                                        // value={form1State.formData.bankStatements}
                                        className="form-control"
                                        onChange={productImageHandler}
                                    />
                                </div>
                            </div>

                            {/* addresProof */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        name="address"
                                        value={form1State.formData.address}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* City */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={form1State.formData.city}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* State */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        value={form1State.formData.state}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* Country */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Country</label>
                                    <input
                                        type="text"
                                        name="country"
                                        value={form1State.formData.country}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* Street */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Street</label>
                                    <input
                                        type="text"
                                        name="street"
                                        value={form1State.formData.street}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            {/* Country Code */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Country Code</label>
                                    <input
                                        type="text"
                                        name="countryCode"
                                        value={form1State.formData.countryCode}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Pincode</label>
                                    <input
                                        type="text"
                                        name="pincode"
                                        value={form1State.formData.pincode}
                                        className="form-control"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row p-1 ml-2">
                            <button className="btn btn-primary" onClick={submitHandler} disabled={isSubmitting} >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
