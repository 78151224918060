import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { Link } from 'react-router-dom';


export default function SellerList() {

    const [financeSellerList, setFinanceSellerList] = useState([])

    useEffect(() => {
        getFinanceSellerList();
    }, [])

    async function getFinanceSellerList() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/getprovider`,
                method: ""
            })

            if (data?.status === 200) {
                setFinanceSellerList(data?.data)
            }
        } catch (err) {
            console.log(err)
            toast.err("Error fetching sellers!")
        }


    }

    async function deleteHandler(id) {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/deleteprovider/${id}`,
                method: "POST"
            })

            if (data?.status === "success") {
               toast.error("Seller deleted");
               getFinanceSellerList();
            }
        } catch (err) {
            console.log(err)
            toast.err("Error in deleting!")
        }
    }


    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "provider_name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            accessor: "provider_email",
            sticky: "left"
        },
        {
            Header: "ONDC Provider Id",
            Footer: "ONDC Provider Id",
            accessor: "ondc_provider_id",
            sticky: "left"
        },
        // {
        //     Header: "Status",
        //     Footer: "Status",
        //     accessor: (row) => {
        //         return <>{row.status ? <span className="badge badge-success">Active</span> :
        //             <span className="badge badge-danger">Deactive</span>}</>
        //     },
        //     sticky: "left"
        // },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row) => {

                return (
                    <div className='d-flex justify-content-center' style={{ gap: "10px" }}>
                        <Link to={`/finance/addseller/${row.id}`}>   <button className='btn btn-sm btn-success'>Edit</button></Link>
                        <button className='btn btn-sm btn-danger' onClick={() => {
                            deleteHandler(row.id)
                        }} >Delete</button>
                    </div>
                )
            },
            sticky: "left"
        },

    ];

    return (
        <ThemeMaker>

            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left">
                                Seller list
                            </h6>

                        </div>

                        <div className="card-body p-0 p-1" style={{ minHeight: "70vh" }}>
                            <Paginated data={financeSellerList} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker>
    )
}
