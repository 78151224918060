import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../../utils/config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function Personalform1() {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    // const [transactionId, setTransactionId] = useState(null);
    // const [messageId, setMessageId] = useState(null);
    const [submitionID, setSubmition] = useState("");

    const [form1State, setForm1State] = useState({
        insauranceType: "",
        formData: {
            firstName: "",
            lastName: "",
            officialemail: "",
            city: "",
            state: "",
            addressL1: "",
            addressL2: "",
            personalemail: "",
            contactNumber: "",
            employmentType: "",
            dob: "",
            gender: "",
            panName: "",
            pan: "",
            endUse: "",
            bureauConsent: "",
            companyName: "",
            income: "",
            pincode: "",
            udyamNumber: "",
            aa_id: "01",
        },
    });

    const [isSubmitting, setisSubmitting] = useState(false);
    const [ifFormVisible, setIsFormVisible] = useState(true)
    //   useEffect(() => {
    //     const queryString = window.location.search;
    //     const urlParams = new URLSearchParams(queryString);
    //     setTransactionId(urlParams.get("transaction_id"));
    //     setMessageId(urlParams.get("message_id"));
    //   }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        // setMessageId(searchParams?.get("message_id"));
        // setTransactionId(searchParams?.get("transaction_id"));
    }, [location.search]);

    function onChangeHandler(e) {
        setForm1State((prev) => {
            return {
                ...prev,
                formData: { ...prev.formData, [e.target.name]: e.target.value },
            };
        });
    }
    //   console.log(form1State?.formData);

    async function submitHandler(e) {

        e.preventDefault();
        let apiEndpoint = NodebaseUrlDefiner();
        function checkFormData(data) {
            for (const key in data) {
                let notrewuiredKeys = ["officialemail", "companyName", "udyamNumber", "addressL2"];
                if (notrewuiredKeys.some((item) => item === key)) {
                    continue;
                }
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    return false;
                }
            }
            return true;
        }

        if (checkFormData(form1State.formData)) {
            try {
                setisSubmitting(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/credit/searchForm`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem(
                            "us_st_d"
                        )}`,
                    },
                    data: {
                        ...form1State?.formData,
                        bureauConsent: Boolean(form1State?.formData?.bureauConsent),
                        formId: params?.id
                        // transaction_id: transactionId,
                        // message_id: messageId,
                    },
                });

                if (data.message === "Form Data Submitted Successfully") {
                    toast.success("Form Submitted Successfully.");
                    setForm1State({
                        insauranceType: "",
                        formData: {
                            officialemail: "",
                            city: "",
                            state: "",
                            addressL1: "",
                            addressL2: "",
                            personalemail: "",
                            contactNumber: "",
                            employmentType: "",
                            dob: "",
                            gender: "",
                            panName: "",
                            pan: "",
                            endUse: "",
                            bureauConsent: "",
                            companyName: "",
                            income: "",
                            pincode: "",
                            udyamNumber: "",
                            aa_id: "01",
                        },
                    });
                    setSubmition(data?.submission_id ?? "")

                    // history.push(`/thankyou/${data?.submission_id}`);
                } else {
                    toast.error("Error In Submitting Form.");
                }
            } catch (err) {
                toast.error("Server not working, Please try again later!");
            } finally {
                setisSubmitting(false);
            }
        } else {
            toast.error("Please fill all fields!");
        }
    }
    useEffect(() => {
        const fetchVisibilityStatus = async () => {

            let apiEndpoint = NodebaseUrlDefiner();
            try {
                // const response = await fetch('https://yourapi.com/visibility'); // Replace with your actual API endpoint
                // const result = await response.json();
                const { data } = await axios({
                    url: `${apiEndpoint}/credit/formVisibility`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem(
                            "us_st_d"
                        )}`,
                    },
                    data: {
                        form_id: params?.id
                    },
                });
                if (data.status === true) {
                    setIsFormVisible(true);  // Set visibility based on response
                } else {
                    setIsFormVisible(false);
                    toast.error(data.msg || "Form is not available");
                }
            } catch (error) {
                // console.error("Error fetching visibility status:", error);
                // if (error.response) {
                //     // Log the full error response for better debugging
                //     console.error("Error response data:", error.response.data);
                //     console.error("Error response status:", error.response.status);
                //     console.error("Error response headers:", error.response.headers);
                // }
                setIsFormVisible(false);
                // toast.error(error.response.data.msg);
            }
        };

        fetchVisibilityStatus();
    }, [params.id]);
    return (
        <>
            {submitionID ? (
                <>
                    <div>
                        <pre>{JSON.stringify({ submission_id: submitionID }, null, 2)}</pre>
                    </div>
                </>
            ) : ifFormVisible ? (
                <>
                    <div className="row w-100 m-0 mt-1">
                        <div className="col-md-12 ">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-company_name-bold text-primary text-left d-flex justify-content-between align-items-center">
                                        Loan Form
                                        <div className="d-flex" style={{ gap: "20px" }}></div>
                                    </h6>
                                </div>

                                <form onSubmit={submitHandler} action={`/personal/loan/search/${params?.id}`} method="post" >
                                    <div className="card-body" style={{ minincome: "90vh" }}>
                                        <div className="row d-none">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="">
                                                        Need Insaurance for
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="type"
                                                        value={form1State?.formData?.type}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value="self">Self</option>
                                                        <option value="family">Family</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 m-0">
                                            <div className="col-md-3 d-none">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="">
                                                        Relationship
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="relationship_with_proposer"
                                                        id=""
                                                        className="form-control"
                                                        value={form1State.formData.relationship_with_proposer}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value="wife">Wife</option>
                                                        <option value="husband">Husband</option>
                                                        <option value="son">Son</option>
                                                        <option value="daughter">Daughter</option>
                                                        <option value="spouse">Spouse</option>
                                                        <option value="father">Father</option>
                                                        <option value="mother">Mother</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="firstName">
                                                        First Name <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        value={form1State.formData.firstName}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="lastName">
                                                        Last Name
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        value={form1State.formData.lastName}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="personalemail">
                                                        Personal email
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="personalemail"
                                                        id="personalemail"
                                                        className="form-control"
                                                        value={form1State.formData.personalemail}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="officialemail">
                                                        Official email
                                                        {/* <span className="text-danger" > * </span> */}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="officialemail"
                                                        id="officialEmail"
                                                        className="form-control"
                                                        value={form1State.formData.officialemail}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="contactNumber">
                                                        Contact number
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="contactNumber"
                                                        id="contactNumber"
                                                        className="form-control"
                                                        value={form1State.formData.contactNumber}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="addressL1">
                                                        Address Line 1<span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="addressL1"
                                                        id="addressL1"
                                                        className="form-control"
                                                        value={form1State.formData.addressL1}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="addressL2">
                                                        Address Line 2
                                                        {/* <span className="text-danger" > * </span> */}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="addressL2"
                                                        id="addressL2"
                                                        className="form-control"
                                                        value={form1State.formData.addressL2}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="city">
                                                        City
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        className="form-control"
                                                        value={form1State.formData.city}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" for="state">
                                                    <label className="genericlabel">
                                                        State
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="state"
                                                        id="state"
                                                        className="form-control"
                                                        value={form1State.formData.state}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="pincode">
                                                        Pincode
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="pincode"
                                                        id="pincode"
                                                        className="form-control"
                                                        value={form1State.formData.pincode}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="dob">
                                                        DOB
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="dob"
                                                        id="dob"
                                                        value={form1State.formData.dob}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="panName">
                                                        PAN Name
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="panName"
                                                        id="panName"
                                                        value={form1State.formData.panName}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="panName">
                                                        PAN Number
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="pan"
                                                        id="pan"
                                                        value={form1State.formData.pan}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="companyName">
                                                        Company Name
                                                        {/* <span className="text-danger" > * </span> */}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="companyName"
                                                        id="companyName"
                                                        className="form-control"
                                                        value={form1State.formData.companyName}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="income">
                                                        Income
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="income"
                                                        className="form-control"
                                                        id="income"
                                                        value={form1State.formData.income}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="gender">
                                                        Gender
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="gender"
                                                        className="form-control"
                                                        id="gender"
                                                        value={form1State.formData.gender}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="employmentType">
                                                        Employement Type
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="employmentType"
                                                        className="form-control"
                                                        id="employmentType"
                                                        value={form1State.formData.employmentType}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value="salaried">Salaried</option>
                                                        <option value="business">Business</option>
                                                        <option value="un_employed">Un-employed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="endUse">
                                                        End Use
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="endUse"
                                                        id="endUse"
                                                        className="form-control"
                                                        value={form1State.formData.endUse}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value="education">Education</option>
                                                        <option value="business">Business</option>
                                                        <option value="contruction">Contruction</option>
                                                        <option value="medical">Medical</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="bureauConsent">
                                                        Bureau Consent
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        name="bureauConsent"
                                                        id="bureauConsent"
                                                        className="form-control"
                                                        value={form1State.formData.bureauConsent}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="" disabled selected>
                                                            Select
                                                        </option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="genericlabel" for="udyamNumber">
                                                        Udyam Number
                                                        {/* <span className="text-danger" > * </span> */}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="udyamNumber"
                                                        id="udyamNumber"
                                                        value={form1State.formData.udyamNumber}
                                                        className="form-control"
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Aggregator ID
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="aa_id" value={form1State.formData.aa_id} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div> */}
                                            <input
                                                type="hidden"
                                                id="formId"
                                                name="formId"
                                                value={params?.id}
                                            />
                                        </div>
                                        {/* <div className="row p-1 ml-2">
                            <button type="submit" className="btn btn-primary" onClick={submitHandler} disabled={isSubmitting} >Submit</button>
                        </div> */}
                                        <div className="row p-1 ml-2">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    <h1 style={{
                        display: 'block',
                        fontSize: '2em',
                        marginBlockStart: '0.67em',
                        marginBlockEnd: '0.67em',
                        marginInlineStart: '0px',
                        marginInlineEnd: '0px',
                        fontWeight: 'bold',
                        unicodeBidi: 'isolate'
                    }}> Invalid URL</h1>
                    <h2 style={{
                        display: 'block',
                        fontSize: '1.5em',
                        marginBlockStart: '0.83em',
                        marginBlockEnd: '0.83em',
                        marginInlineStart: '0px',
                        marginInlineEnd: '0px',
                        fontWeight: 'bold',
                        unicodeBidi: 'isolate'
                    }}>The URL is either incorrect, already used, or is no longer available.</h2></>
            )}
        </>
    );
}
