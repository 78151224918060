
import { useState } from "react"
import axios from "axios"
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../../utils/config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import aws from 'aws-sdk';
import { config } from "../../../utils/config";
import { removeExifData } from "../../NewmasterPage/helper";
const { awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;

export default function Personalform2() {

    const params = useParams()

    const [form1State, setForm1State] = useState({
        insauranceType: "",
        formData: {
            "PAN": "",
            "city": "",
            "state": "",
            "address": "",
            "addresProof": "",
            "pincode": "",
            "address": "",
            "country": "",
            "street": "",
            "countryCode": "+91",
            "income_proof_file": "",
            "address_proof_file": ""

        }
    })

    const [isSubmitting, setisSubmitting] = useState(false)

    function onChangeHandler(e) {
        setForm1State(prev => {
            return { ...prev, formData: { ...prev.formData, [e.target.name]: e.target.value } }
        })
    }
    console.log(form1State?.formData)
    async function submitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        function checkFormData(data) {
            for (const key in data) {
                // let notrewuiredKeys = ["official_email", "company_name", "address", "addressL2",]
                // if (notrewuiredKeys.some(item => item === key)) {
                //     continue;
                // }
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    return false;
                }
            }
            return true;
        }

        if (checkFormData(form1State.formData)) {
            try {
                setisSubmitting(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/credit/selectForm`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        ...form1State.formData,
                        form_id: params?.id // Assuming formId is defined elsewhere
                    }
                });

                if (data.success) {
                    toast.success("Form Submitted Successfully.");
                    setForm1State({
                        insauranceType: "",
                        formData: {
                            "PAN": "",
                            "city": "",
                            "state": "",
                            "address": "",
                            "addresProof": "",
                            "pincode": "",
                            "address": "",
                            "country": "",
                            "street": "",
                            "countryCode": "+91",
                            "income_proof_file": "",
                            "address_proof_file": ""

                        }
                    })
                } else {
                    toast.error("Error In Submitting Form.");
                }
            } catch (err) {
                toast.error("Server not working, Please try again later!");
            } finally {
                setisSubmitting(false); // Ensure this is called in all cases
            }
        } else {
            toast.error("Please fill all fields!");
        }
    }

    const productImageHandler = async (e, item) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];

            // Remove the Exif metadata using the custom function
            const modifiedArrayBuffer = await removeExifData(file);
            // Convert the modified data back to a Blob
            const strippedBlob = new Blob([modifiedArrayBuffer], { type: file.type });

            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
            });

            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: strippedBlob, // Use the stripped file
                ContentType: file?.type
            };

            let result = await s3.upload(params).promise();
            const { Location } = result;
            setForm1State(prev => {
                return { ...prev, formData: { ...prev.formData, [item]: Location } }
            })


        } catch (exception) {
            console.error('Error during image upload:', exception);
        }
    };



    return (

        <div className="row w-100 m-0 mt-1">
            <div className="col-md-12 ">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-company_name-bold text-primary text-left d-flex justify-content-between align-items-center">
                            Loan  Form
                            <div className="d-flex" style={{ gap: "20px" }}>
                                {/* <button className="text-center btn btn-secondary" onClick={() => {
                                        setshipmentModalOpen(true)
                                    }}>Import</button>
                                    <Link to="/producttypelist" >
                                        <button className="btn btn-primary">Product Type List</button>
                                    </Link> */}
                            </div>


                        </h6>
                    </div>

                    <div className="card-body" style={{ minincome: "90vh" }}>


                        <div className="row p-0 m-0">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        PAN Number
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="PAN" value={form1State.formData.PAN} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Address Proof
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="addresProof" id="" className='form-control' value={form1State.formData.addresProof} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="Aadhar Card">Aadhar Card</option>
                                        <option value="PAN Card">PAN Card</option>
                                        <option value="Utility Bill">Utility Bill</option>
                                        <option value="Water Bill">Water Bill</option>
                                        <option value="Bank Passbook">Bank Passbook </option>
                                        <option value="Passport">Passport </option>
                                    </select>
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Address Proof File
                                        <span className="text-danger" > * </span>

                                    </label>

                                    <input type="file" id="image" accept="image/*" name="address_proof_file" multiple className='form-control' onChange={(e) => productImageHandler(e, "address_proof_file")}
                                    />
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Address Line
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="address" value={form1State.formData.address} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Street
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="street" value={form1State.formData.street} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        City
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="city" value={form1State.formData.city} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        State
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="state" value={form1State.formData.state} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Country
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="country" value={form1State.formData.country} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Pincode
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="pincode" value={form1State.formData.pincode} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Income Proof
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="incomeProof" id="" className='form-control' value={form1State.formData.incomeProof} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="Salary Slip">Salary Slip</option>
                                        <option value="Bank Statement">Bank Statement</option>

                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Income Proof File
                                        <span className="text-danger" > * </span>

                                    </label>

                                    <input type="file" id="image" accept="image/*" name="income_proof_file" multiple className='form-control' onChange={(e) => productImageHandler(e, "income_proof_file")}
                                    />
                                </div>

                            </div>

                        </div>

                        <div className="row p-1 ml-2">

                            <button className="btn btn-primary" onClick={submitHandler} disabled={isSubmitting} >Submit</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
