import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { checkLogin } from "../../store/features/login/loginSlice";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import requesticon from "../../icons/request.png"
import producticon from "../../icons/product.png"
import sellericon from "../../icons/seller.png"
import ordericon from "../../icons/order.png"
import totalproduct from "../../icons/Totalproduct.gif"
import totalorder from "../../icons/Totalorder.gif"
import pendingicon from "../../icons/Pendingrequest.gif"
import totalseller from "../../icons/Totalseller.gif"
import totalrevenue from "../../icons/totalrevenue3.gif"
import axios from "axios";
import styles from "./dashboard.module.css"
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BarChart } from '@mui/x-charts/BarChart';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { AgChartsReact } from 'ag-charts-react';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import sellerDetails from "../../icons/sellerDetailsss.png"
import { baseUrlDefiner } from '../../utils/config';
// import { ChartContainer } from '@mui/x-charts';
// import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';

const { apiEndpoint } = config;
var $ = require('jquery');

const Dashboard = () => {
    const [Dashboard, setDashboard] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const history = useHistory();
    const decode = tokenDecode();
    const { data } = decode;
    const id = data.user.seller_id;
    const [sidebarShow, setsidebarShow] = useState(checkstatus());
    const [pData, setpData] = useState([5, 10, 15, 20, 25, 30, 35]);
    const dateData = new Date();
    const month = dateData.getMonth() + 1;
    const year = dateData.getFullYear();
    const date = dateData.getDate();


    // const vvvv = (`${year}-0${month}-0${date}`)
    // console.log("vvvv",vvvv)
    let dateformat: any;
    if (date < 10) {
        dateformat = `${year}-0${month}-0${date}`;
    } else {
        dateformat = `${year}-0${month}-${date}`;
    }
    // console.log(dateformat)

    const currentDate = new Date(dateformat);
    const sevenDaysAgo = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
    const fifteenDaysAgo = new Date(currentDate.getTime() - (15 * 24 * 60 * 60 * 1000));
    const thirtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
    const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split('T')[0];
    const formattedfifteenDaysAgo = fifteenDaysAgo.toISOString().split('T')[0];
    const formattedthirtyDaysAgo = thirtyDaysAgo.toISOString().split('T')[0];
    const [hasOrder, setHasorders] = useState(0)
    const [xLabels, setxLables]: any = useState([]);
    const [request, setrequest]: any = useState([])
    const [shopifyrequest, setshopifyrequest]: any = useState([])
    const [filterData, setFilterdata] = useState({
        start_date: dateformat,
        end_date: dateformat
    })

    const [ReqResfilterDaata, setReqResfilterDaata] = useState({
        seller_id: "",
        endDate: ""
    })
    const [ShopifyfilterData, setShopifyFilterdata] = useState({
        start_date: dateformat,
        end_date: dateformat
    })
    // useEffect(() => {

    //     CountListing()

    // }, [filterData])

    // useEffect(() => {
    //     ShopifyCountListing()
    // }, [ShopifyfilterData])


    const [reqresLoading, setreqresLoading] = useState(false)
    const [reqresShopifyLoading, setreqresShopifyLoading] = useState(false)
    const [x3Labels, setx3Labels] = useState([
        'Select',
        'Init',
        'Confirm',

    ])

    const [x2Labels, setx2Labels] = useState([
        'LBP',
        'Simple Skincare',
        'Dhampur Green',

    ])


    function checkUser() {
        if (id > 1) {
            return data.seller_data.provider_id
        }

        if (id < 1) {
            return data.seller_data.provider_id
        }

        if (id == 1) {
            return ""
        }
    }
    const [selectedProviderId, setSelectedProviderId] = useState(checkUser())
    const [PieSelectedProviderId, setPieSelectedProviderId] = useState(checkUser())
    const [sellerList, setSellerList] = useState([]);
    const [isnoOrders, setisnoOrders] = useState(false);
    const [pieChartData, setpieChartData] = useState({
        "cancelled": 0,
        "accepted": 0,
        "in_progress": 0,
        "completed": 0
    })
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
    const [ShopifyshipmentModalOpen, setShopifyshipmentModalOpen] = useState(false);
    const [allDashboardData, setallDashboardData] = useState<any>()
    const Sstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
    };
    const [chartOptions, setChartOptions] = useState<any>({
        // Data: Data to be displayed in the chart
        data: [
            { order_date: "12", total_orders: 14 },
            { order_date: "13", total_orders: 20 },
            { order_date: "14", total_orders: 30 },
            { order_date: "15", total_orders: 7 },
            { order_date: "15", total_orders: 7 },
            { order_date: "15", total_orders: 7 },
        ],
        // Series: Defines which chart type and data to use
        series: [{ type: 'bar', xKey: 'order_date', yKey: 'total_orders' }],
        background: {
            fill: "white",
        },

        title: { text: 'Daily order Count vise sells matrix' },
    });
    function checkstatus() {
        const token: any = sessionStorage.getItem("us_st_d");
        const tokenDecode: any = jwt_decode(token)
        return tokenDecode.data.user.approve_status
    }

    const logouthandler = () => {
        sessionStorage.removeItem("us_st_d");
        history.push("/")
    }

    function handleShipementModalClose() {
        setshipmentModalOpen(false)
    }
    function handleShopifyShipementModalClose() {
        setShopifyshipmentModalOpen(false)
    }

    const role_id = data.user.role_id;
    const user_id = data.user.id;
    const dat = useSelector((state: any) => state.loginReducer);
    const { permission: { category, order, product, role, seller, tax, user } } = data;

    const dashboardListing = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/total_countdata`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id
                }
            });

            if (data.status === 200) {
                setallDashboardData(data.data);
                setHasorders(data?.data?.total_order)
                setLoading(false);
                setDashboard(Object.values(data.data));
            }
            if (data.status == "Token is Expired") {
                logouthandler()
            }
        } catch (error) {
        }
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const chartWidth = isMobile ? 260 : 1200; // Adjust the width for mobile view
    const chartHeight = isMobile ? 260 : 300; // Adjust the height for mobile view
    const chartHeightLine = isMobile ? 260 : 230; // Adjust the height for mobile view
    const chartWidthLine = isMobile ? 260 : 500;


    useEffect(() => {
        dashboardListing();
        fetchSellerList();
        getPieChartData();
    }, [])

    useEffect(() => {
        getGraphData();
    }, [selectedProviderId])

    useEffect(() => {
        getPieChartData();
    }, [PieSelectedProviderId])



    const fetchSellerList = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                }
            });

            if (data.status === 200) {
                setSellerList(data.data);
                console.log("Data", data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    let paths: any;
    if (id == 1) {
        paths = [
            { path: "/masterproductlist" },
            { path: "/sellerlist" },
            { path: "/orderlist" },
            { path: "/nonapprovedproducts" },

        ]
    } else {
        paths = [
            { path: "/" },
            { path: "/orderlist" },
            { path: "/catalog" },
            { path: "/" },

        ]
    }

    async function getGraphData() {
        let apiEndpoint = baseUrlDefiner();
        try {


            const { data } = await axios({
                url: `${apiEndpoint}/sevenDaysFilter`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    "provider_id": selectedProviderId
                }
            });

            if (data?.data.length > 0) {
                let z = data.data.map((item: any) => item.total_orders)
                let y = data.data.map((item: any) => item.order_date)
                setpData([...z])
                setxLables([...y])
                setChartOptions((prev: any) => {
                    return {
                        ...prev, data: data.data
                    }
                })

            } else {
                setisnoOrders(true)
            }
        } catch (err) {
            console.log(err)
        }
    }
    async function getPieChartData() {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/orderStatuAnalysis`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    "provider_id": PieSelectedProviderId
                }
            });
            setpieChartData(data.data);
            setOptions((prev: any) => {
                return {
                    ...prev,
                    data: [
                        { label: 'Delivered', value: data.data.completed },
                        { label: 'Accepted', value: data.data.accepted },
                        { label: 'In-Progress', value: data.data.in_progress },
                        { label: 'Cancelled', value: data.data.cancelled },

                    ]

                }
            })
        } catch (err) {
            console.log(err)

        }
    }

    async function linegraphFilterHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        setisnoOrders(false)
        setSelectedProviderId(e.target.value)
    }

    async function piegraphFilterHandler(e: React.ChangeEvent<HTMLSelectElement>) {

        setPieSelectedProviderId(e.target.value)
    }

    const NewDate = new Date();
    const todayDate = NewDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });


    const changeHandler = (e: any) => {

        if (e.target.value === "7") {
            setFilterdata({
                start_date: formattedSevenDaysAgo,
                end_date: dateformat
            })
        }

        if (e.target.value === "15") {
            setFilterdata({
                start_date: formattedfifteenDaysAgo,
                end_date: dateformat
            })
        }
        if (e.target.value === "30") {
            setFilterdata({
                start_date: formattedthirtyDaysAgo,
                end_date: dateformat
            })
        }
    }
    const changeShopifyHandler = (e: any) => {

        if (e.target.value === "7") {
            setShopifyFilterdata({
                start_date: formattedSevenDaysAgo,
                end_date: dateformat
            })
        }

        if (e.target.value === "15") {
            setShopifyFilterdata({
                start_date: formattedfifteenDaysAgo,
                end_date: dateformat
            })
        }
        if (e.target.value === "30") {
            setShopifyFilterdata({
                start_date: formattedthirtyDaysAgo,
                end_date: dateformat
            })
        }
    }

    const [confermSaved, setConfermedSaved] = useState("")
    const CountListing = async () => {
        if (filterData.end_date < filterData.start_date) {
            alert("End Date should be ahead of Start Date!")
            return
        }
        try {
            setreqresLoading(true)
            const { data } = await axios({
                url: `https://ondc.xpressbaazaar.com/analytics/action_analytics`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: filterData
            });
            const { on_select_action, on_init_action, on_confirm_action, select_action, init_action, confirm_action, confirm_request_saved } = data
            setConfermedSaved(confirm_request_saved)
            setrequest([
                { data: [select_action, init_action, confirm_action], label: 'Request', id: 'uvId' },
                { data: [on_select_action, on_init_action, on_confirm_action], label: 'Response', id: 'pvId' },

            ])

            setreqresLoading(false)

        } catch (error) {
            setreqresLoading(false)
            console.log(error)
        }
    }


    const ShopifyCountListing = async () => {
        if (ShopifyfilterData.end_date < ShopifyfilterData.start_date) {
            alert("End Date should be ahead of Start Date!")
            return
        }

        try {
            setreqresShopifyLoading(true)
            const { data } = await axios({
                url: `https://ondc.xpressbaazaar.com/analytics/shopify_order_analysis`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: ShopifyfilterData
            });

            if (data?.length > 0) {
                let y = data?.map((item: any) => {
                    return item?.shopify_order_exists
                })
                let x = data?.map((item: any) => {
                    return item?.order_exists
                })

                let x2Labelssss = data?.map((item: any) => item?.name)
                setx2Labels([...x2Labelssss])
                setshopifyrequest([
                    { data: [...y], label: 'On CMS', id: 'pvId' },
                    { data: [...x], label: 'On Platform', id: 'uvId' },
                ])
            } else {
                setShopifyFilterdata({
                    start_date: formattedSevenDaysAgo,
                    end_date: dateformat
                })
            }


            setreqresShopifyLoading(false)

        } catch (error) {
            setreqresShopifyLoading(false)
            console.log(error)
        }
    }


    async function filterChangeHandler(e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
        setFilterdata((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }
    async function filterShopifyChangeHandler(e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
        setShopifyFilterdata((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month: string | number = today.getMonth() + 1;
        let day: string | number = today.getDate();

        // Add leading zero if month or day is a single digit
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    // console.log("dashboard", allDashboardData)
    const steps = [
        'Low',
        'Medium',
        'High',
    ];

    const percentageCompleted = (pieChartData?.completed / allDashboardData?.total_order) * 100;
    const percentageCompletedInprg = (pieChartData?.in_progress / allDashboardData?.total_order) * 100;

    function getData() {
        return [
            { asset: 'Accepted', amount: pieChartData.accepted ? 5 : 1 },
            { asset: 'In-Progress', amount: pieChartData.in_progress },
            { asset: 'Delivered', amount: pieChartData.completed },
            { asset: 'Cancelled', amount: pieChartData.cancelled },

        ];
    }


    const [options, setOptions] = useState<any>({
        data: [
            { label: 'Accepted', value: pieChartData.accepted },
            { label: 'In-Progress', value: pieChartData.in_progress },
            { label: 'Delivered', value: pieChartData.completed },
            { label: 'Cancelled', value: pieChartData.cancelled },

        ],
        width: 300,

        series: [
            {
                type: "pie",
                angleKey: "amount",
                calloutLabelKey: "asset",
                sectorLabelKey: "amount",
                sectorLabel: {
                    color: "white",
                    fontWeight: "bold",
                    formatter: ({ value }: any) => `${(value)}`,
                },
            },
        ],

    });

    function getValueInKFormat(value: string | number) {
        let convertedNumber = parseInt(allDashboardData?.total_revenue);
        let decimalNumber = Math.round(convertedNumber);
        let toReturnValue = decimalNumber + "K"
        return toReturnValue
    }


    const data2 = [
        { label: 'Delivered', value: 6991 },
        { label: 'Accepted', value: 30 },
        { label: 'In-progress', value: 200 },
        { label: 'Cancelled', value: 497 },
    ];

    const palette = ['#459d55', 'rebeccapurple', 'orange', '#3498db'];

    return (

        <ThemeMaker>
            {sidebarShow === 1 ? <div className={`container-fluid  ${styles.mobile_dashboard}`}>

                <div className={`d-sm-flex align-items-center justify-content-between my-2 ${styles.heading}`}>
                    <h1 className="h3 mb-0 d-flex" style={{ gap: "15px" }}>
                        {/* <i className="fa fa-home" aria-hidden="true" ></i>  */}
                        Dashboard</h1>
                    {/* <span>Dashboard & Statics</span> */}
                </div>
                <div className={styles.showOnDesktop}>
                    <div className="row">
                        {isLoading ? <div className="loader-wrapper"><div className="loader"></div></div> : null}

                        {Dashboard.map((item, index) => {

                            // const abc = paths.find((xyz: any, i: any) => i == index)
                            // if (id > 1 && index === 0) {
                            //     return null;
                            // }

                            return <div className="col-xl-3 col-md-6 mb-4" key={index}>
                                <div className={`card border-left-primary shadow h-100 py-3 ${styles.cardSttyle}`}>
                                    <div className="card-body d-flex " style={{ padding: "1px 10px" }} >
                                        <div className="row no-gutters align-items-center w-100">

                                            <div className="col mr-2">
                                                {index === 0 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Product" : "Total Order"}</div> : null}
                                                {index === 1 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Seller" : "Total Product"}</div> : null}
                                                {index === 2 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Order" : "Total Revenue"}</div> : null}
                                                {index === 3 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}> {id === 1 ? "Open Tickets" : "Open Tickets"}</div> : null}
                                                {/* {(index === 4 && (id > 1 || data.user.parent_id > 1)) ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Total Revenue </div> : null}
                                            {(index === 4 && id == 1) ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Open Tickets </div> : null}
                                            {index === 5 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Open Tickets </div> : null} */}
                                                <div className={`h5 mb-0 font-weight-bold  ${styles.count}`} >{item}</div>

                                            </div>
                                            <div className={`col-auto ${styles.iconColor}`}>
                                                {index === 0 ? <>{id === 1 ? <img src={totalproduct} style={{ width: "100px" }} alt="" /> : <img src={totalorder} style={{ width: "120px" }} alt="" />} </> : null}
                                                {index === 1 ? <>{id === 1 ? <img src={totalseller} alt="" style={{ width: "100px" }} /> : <img src={totalproduct} style={{ width: "100px" }} alt="" />} </> : null}
                                                {index === 2 ? <>{id === 1 ? <img src={totalorder} style={{ width: "120px" }} alt="" /> : <img style={{ width: "120px" }} src={totalrevenue} alt="" />} </> : null}
                                                {index === 3 ? <img src={pendingicon} style={{ width: "70px" }} alt="" /> : null}
                                                {/* {(index === 4 && (id > 1 || data.user.parent_id > 1)) ? <img src={ordericon} alt="" /> : null}
                                            {(index === 4 && id == 1) ? <PendingActionsIcon style={{ width: "60px", height: "70px", color: "#2392ff" }} /> : null}
                                            {(index === 5) ? <PendingActionsIcon style={{ width: "60px", height: "70px", color: "#2392ff" }} /> : null} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        }
                        {/* 
 {(id > 1 && !isLoading ) ? <div className="bg-white col-xl-3 mb-4 card border-left-primary shadow ">
                        <div>
                            <p > <span className="genericlabel">Fraud analysis</span> </p>
                            <div>
                                <div className="progress">
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "38%" }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <div className="d-flex justify-content-around mt-2" >
                                    {steps.map(item => {
                                        return <span className="genericlabel">{item}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div> : null
                    } */}
                    </div>


                    {hasOrder > 0 && <> {!isLoading && <div className={`mt-2 mb-4 d-flex ${styles.graphMain}`}>
                        {(xLabels.length > 0 && pData.length > 0) && <>
                            <div className={`border bg-white ${styles.lineGraphMain} ${id !== 1 ? `w-50` : `w-100`}`} >
                                <p className="m-0 pt-2 pl-2 d-flex justify-content-between align-items-center">

                                    <span className={`${styles.graphHeading}`} > Orders count for last 25 days</span>

                                    <div className="d-flex mr-1" style={{ gap: "6px" }}>  <span className={styles.calenderstyle} > <CalendarMonthRoundedIcon style={{ color: "#105293" }} /> Month-Oct</span>
                                        {id === 1 ? <select name="" id="" className="mr-2" style={{ background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px" }} onChange={linegraphFilterHandler} >
                                            <option value="">All Sellers</option>
                                            {
                                                sellerList.filter((item: any) => item.status === true && (item.approve_status === 1)).map((item: any, index) => {
                                                    return <option value={item.provider_id}>{item?.name}</option>
                                                })
                                            }
                                        </select> : null} </div>
                                </p>

                                <hr />

                                <div className={!isnoOrders ? `d-flex justify-content-center` : "d-none"}>

                                    <LineChart
                                        sx={{ stroke: "grey", }}
                                        disableAxisListener
                                        width={chartWidth}
                                        height={chartHeight}
                                        series={[
                                            { data: pData, label: 'Orders Count', yAxisKey: 'leftAxisId', color: '#4e73df', showMark: false },
                                            // { data: uData, label: 'uv', yAxisKey: 'rightAxisId' },
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: xLabels }]}
                                        yAxis={[{ id: 'leftAxisId' }]}

                                    // rightAxis="rightAxisId"
                                    />
                                </div>
                                <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center">
                                    <p className={isnoOrders ? ` text-center` : "d-none"} >No Orders !!</p>
                                </div>

                            </div>
                        </>


                        }

                        {
                            id !== 1 && <>
                                <div className={`border  bg-white ${styles.lineGraphMain}`}>

                                    <> <div>
                                        <p className="m-0 pt-2 pl-2 d-flex justify-content-between align-items-center">

                                            <span className={`${styles.graphHeading}`} >Order status vise sales matrix</span>
                                            {/* <span className={`${styles.graphHeading} px-2`} >{`Average Order Value : `}</span> */}

                                            <div className="d-flex mr-1 " style={{ gap: "6px" }}><span className={styles.calenderstyle} > <CalendarMonthRoundedIcon style={{ color: "#105293" }} /> Month-Oct</span>
                                                {id === 1 ? <select name="" id="" className="mr-2" style={{ background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px" }} onChange={piegraphFilterHandler} >
                                                    <option value="">All Sellers</option>
                                                    {
                                                        sellerList.filter((item: any) => item.status === true && (item.approve_status === 1)).map((item: any, index) => {
                                                            return <option value={item.provider_id}>{item?.name}</option>
                                                        })
                                                    }
                                                </select> : null} </div>
                                        </p>

                                        <hr />

                                        <div className={`${(pieChartData?.accepted > 0 || pieChartData?.completed > 0 || pieChartData?.in_progress > 0 || pieChartData.cancelled > 0) ? "d-flex flex-column align-items-center justify-content-center" : "d-none"}`} >
                                            {/* <PieChart
series={[
    {
        data: [

            { id: 1, value: pieChartData?.accepted, label: 'Accepted' },
            { id: 2, value: pieChartData?.in_progress, label: 'In-Progress' },
            { id: 3, value: pieChartData?.completed, label: 'Delivered' },
            { id: 4, value: pieChartData?.cancelled, label: 'Cancelled' },
        ],
        // arcLabel: (item) => `${item.label} (${item.value})`,
        // arcLabelMinAngle: 30,

    },
]}
width={chartWidth}
height={chartHeight}
margin={{ top: 10, bottom: 70, left: 30, right: 30 }}
slotProps={{
    legend: {
        direction: 'row',
        position: { vertical: 'bottom', horizontal: 'middle' }
    },
}}
/> */}

                                            <div className="d-flex mt-2" style={{ gap: "20px" }}>
                                                <div className="d-flex " style={{ gap: "10px" }}>
                                                    <div className={styles.colourCode}></div>
                                                    <p>Accepted</p>

                                                </div>
                                                <div className="d-flex " style={{ gap: "10px" }}>
                                                    <div className={styles.colourCode} style={{ background: "orange" }}></div>
                                                    <p>In-progress</p>
                                                </div>
                                                <div className="d-flex " style={{ gap: "10px" }}>
                                                    <div className={styles.colourCode} style={{ background: "#07bc0c" }}></div>
                                                    <p>Delivered</p>

                                                </div>
                                                <div className="d-flex " style={{ gap: "10px" }}>
                                                    <div className={styles.colourCode} style={{ background: "#3498db" }}></div>
                                                    <p>Cancelled</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center" style={{ paddingLeft: "35px" }}>
                                                <PieChart
                                                    colors={palette}
                                                    series={[
                                                        {
                                                            data: options?.data,
                                                            // cx: 500,
                                                            // cy: 200,
                                                            innerRadius: 40,
                                                            outerRadius: 80,


                                                        },
                                                    ]}
                                                    width={400}  // Adjust width as needed
                                                    height={200}
                                                    slotProps={{
                                                        legend: { hidden: true },
                                                    }}

                                                />


                                            </div>


                                            {/* <AgChartsReact options={options} /> */}
                                        </div>


                                    </div>
                                        <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center">
                                            <p className={`${(pieChartData?.accepted > 0 || pieChartData?.completed > 0 || pieChartData?.in_progress > 0 || pieChartData.cancelled > 0) ? "d-none" : "d-block"}`} >No Orders !!</p>
                                        </div> </>


                                </div>
                            </>
                        }


                    </div>}
                    </>}

                    {!isLoading ? <>
                        {id === 1 && <div className={`mt-4 mb-2 d-none ${styles.graphMain}`} >

                            <div className={`border bg-white ${styles.lineGraphMain}`}>

                                <> <div>
                                    <p className="m-0 pt-2 pl-2 d-flex justify-content-between align-items-center">

                                        <span className={`${styles.graphHeading}`} >Order status vise sales matrix</span>
                                        {/* <span className={`${styles.graphHeading} px-2`} >{`Average Order Value : `}</span> */}

                                        <div className="d-flex mr-1 " style={{ gap: "6px" }}><span className={styles.calenderstyle} > <CalendarMonthRoundedIcon style={{ color: "#105293" }} /> Month-Oct</span>
                                            {id === 1 ? <select name="" id="" className="mr-2" style={{ background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px" }} onChange={piegraphFilterHandler} >
                                                <option value="">All Sellers</option>
                                                {
                                                    sellerList.filter((item: any) => item.status === true && (item.approve_status === 1)).map((item: any, index) => {
                                                        return <option value={item.provider_id}>{item?.name}</option>
                                                    })
                                                }
                                            </select> : null} </div>
                                    </p>

                                    <hr />

                                    <div className={`${(pieChartData?.accepted > 0 || pieChartData?.completed > 0 || pieChartData?.in_progress > 0 || pieChartData.cancelled > 0) ? "d-flex flex-column align-items-center justify-content-center" : "d-none"}`} >
                                        {/* <PieChart
                                series={[
                                    {
                                        data: [

                                            { id: 1, value: pieChartData?.accepted, label: 'Accepted' },
                                            { id: 2, value: pieChartData?.in_progress, label: 'In-Progress' },
                                            { id: 3, value: pieChartData?.completed, label: 'Delivered' },
                                            { id: 4, value: pieChartData?.cancelled, label: 'Cancelled' },
                                        ],
                                        // arcLabel: (item) => `${item.label} (${item.value})`,
                                        // arcLabelMinAngle: 30,

                                    },
                                ]}
                                width={chartWidth}
                                height={chartHeight}
                                margin={{ top: 10, bottom: 70, left: 30, right: 30 }}
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'bottom', horizontal: 'middle' }
                                    },
                                }}
                            /> */}

                                        <div className="d-flex mt-2" style={{ gap: "20px" }}>
                                            <div className="d-flex " style={{ gap: "10px" }}>
                                                <div className={styles.colourCode}></div>
                                                <p>Accepted</p>

                                            </div>
                                            <div className="d-flex " style={{ gap: "10px" }}>
                                                <div className={styles.colourCode} style={{ background: "orange" }}></div>
                                                <p>In-progress</p>
                                            </div>
                                            <div className="d-flex " style={{ gap: "10px" }}>
                                                <div className={styles.colourCode} style={{ background: "#07bc0c" }}></div>
                                                <p>Delivered</p>

                                            </div>
                                            <div className="d-flex " style={{ gap: "10px" }}>
                                                <div className={styles.colourCode} style={{ background: "#3498db" }}></div>
                                                <p>Cancelled</p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ paddingLeft: "35px" }}>
                                            <PieChart
                                                colors={palette}
                                                series={[
                                                    {
                                                        data: options?.data,
                                                        // cx: 500,
                                                        // cy: 200,
                                                        innerRadius: 40,
                                                        outerRadius: 80,


                                                    },
                                                ]}
                                                width={400}  // Adjust width as needed
                                                height={200}
                                                slotProps={{
                                                    legend: { hidden: true },
                                                }}

                                            />


                                        </div>


                                        {/* <AgChartsReact options={options} /> */}
                                    </div>


                                </div>
                                    <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center">
                                        <p className={`${(pieChartData?.accepted > 0 || pieChartData?.completed > 0 || pieChartData?.in_progress > 0 || pieChartData.cancelled > 0) ? "d-none" : "d-block"}`} >No Orders !!</p>
                                    </div> </>


                            </div>

                            <div className={`border  bg-white ${styles.lineGraphMain}`} style={{}}>
                                <div className="card-header d-flex justify-content-between align-items-center bg-white" style={{ borderRadius: "inherit" }}>

                                    <h6 className='genericlabel'>Request/Response</h6>

                                    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                                        {/* <select name="" id="" style={{ background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px" }} onChange={changeHandler} >
                                        <option value="7">Last 7 Days</option>
                                        <option value="15">Last 15 Days</option>
                                        <option value="30">Last 30 Days</option>
                                    </select> */}
                                        <span className="p-1 px-2" style={{ cursor: "pointer", background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px", color: "black" }} onClick={() => {
                                            setshipmentModalOpen(true)
                                        }} >Select Date Range</span>
                                    </div>
                                </div>

                                <div className="card-body d-flex justify-content-center">
                                    <div>
                                        <p className="text-center genericlabel">Showing Results for :- {filterData.start_date} to {filterData.end_date} </p>
                                        {
                                            !reqresLoading ?
                                                <>
                                                    <BarChart
                                                        series={request}
                                                        width={chartWidthLine}
                                                        height={chartHeightLine}
                                                        xAxis={[{ data: x3Labels, scaleType: 'band' }]}
                                                    />
                                                    <p className="text-center genericlabel">Total confirm request saved: {confermSaved}</p>
                                                </>

                                                : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "30vh" }}><CircularProgress /></div>
                                        }


                                    </div>



                                </div>


                            </div>
                            <div className={`${styles.lineGraphMain} border d-none`} style={{}}>
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <h6 className='genericlabel'> Orders Captured </h6>

                                    {/* <div>
                                    <select name="" id="" style={{ background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px" }} onChange={changeShopifyHandler} >
                                        <option value="7">Last 7 Days</option>
                                        <option value="15">Last 15 Days</option>
                                        <option value="30">Last 30 Days</option>
                                    </select>
                                </div> */}
                                    <span className="p-1 px-2" style={{ cursor: "pointer", background: "lightgrey", border: "none", borderRadius: "13px", fontSize: "13px", width: "120px", color: "black" }} onClick={() => {
                                        setShopifyshipmentModalOpen(true)
                                    }} >Select Date Range</span>
                                </div>

                                <div className="card-body ">
                                    <span className='genericlabel'>Showing Results between :- {ShopifyfilterData.start_date} to {ShopifyfilterData.end_date} </span>
                                    {
                                        !reqresShopifyLoading ?
                                            <BarChart
                                                series={shopifyrequest}
                                                width={chartWidth}
                                                height={chartHeight}
                                                xAxis={[{ data: x2Labels, scaleType: 'band' }]}
                                            />
                                            : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "40vh" }}><CircularProgress /></div>
                                    }
                                </div>


                            </div>
                            <>
                                <Modal
                                    open={shipmentModalOpen}
                                    onClose={handleShipementModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={Sstyle} className="col-md-3 w-75 w-lg-25">
                                        <div className="modal-content ">
                                            <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                                <p className="modal-title genericlabel  " style={{ letterSpacing: "3px" }}>Select Dates</p>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="mt-2">
                                                    <label htmlFor="" className="genericlabel" >Start Date</label>
                                                    <input type="date" name="start_date" className="form-control" onChange={filterChangeHandler} value={filterData.start_date} max={getCurrentDate()} />
                                                </div>
                                                <div className="mt-2">
                                                    <label htmlFor="" className="genericlabel" >End Date</label>
                                                    <input type="date" disabled={reqresLoading ? true : false} name="end_date" className="form-control" onChange={filterChangeHandler} value={filterData.end_date} max={getCurrentDate()} />
                                                </div>
                                                <p className='genericlabel text-center'> {reqresLoading ? <span>Please wait to select end date</span> : null}</p>
                                            </div>
                                            {/* <div className="modal-footer">
                                            <button type="button" className="btn btn-danger"  >Clear Filter</button>
                                        </div> */}
                                        </div>
                                    </Box>
                                </Modal>
                            </>
                            <>
                                <Modal open={ShopifyshipmentModalOpen}
                                    onClose={handleShopifyShipementModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={Sstyle} className="col-md-3 w-75 w-md-25">

                                        <div className="modal-content ">
                                            <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                                <p className="modal-title genericlabel  " style={{ letterSpacing: "3px" }}>Select Dates</p>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShopifyShipementModalClose} /></span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="mt-2">
                                                    <label htmlFor="" className="genericlabel" >Start Date</label>
                                                    <input type="date" name="start_date" className="form-control" onChange={filterShopifyChangeHandler} value={ShopifyfilterData.start_date} max={getCurrentDate()} />
                                                </div>
                                                <div className="mt-2">
                                                    <label htmlFor="" className="genericlabel" >End Date</label>
                                                    <input type="date" name="end_date" className="form-control" onChange={filterShopifyChangeHandler} value={ShopifyfilterData.end_date} max={getCurrentDate()} />
                                                </div>
                                            </div>
                                            {/* <div className="modal-footer">
                                            <button type="button" className="btn btn-danger"  >Clear Filter</button>
                                           
                                        </div> */}


                                        </div>
                                    </Box>
                                </Modal>
                            </>
                        </div>

                        }</> : null}
                </div>


                <div className={styles.showOnMobile} >

                    <div className="d-flex justify-content-around my-3" style={{ width: "100%", flexWrap: "wrap", gap: "20px" }}>

                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `} >
                            <img src={producticon} width={30} height={30} />
                            <p className={`mt-1 mb-1 ${styles.counti}`}>{id === 1 ? allDashboardData?.total_product : allDashboardData?.total_sell_product}</p>
                            <p className="" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Products</p>
                        </div>

                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}>
                            <img src={sellericon} width={30} height={30} />
                            <p className={`mt-1 mb-1 ${styles.counti}`}>{id === 1 ? allDashboardData?.total_seller : getValueInKFormat(allDashboardData?.total_revenue)}</p>
                            <p className="" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>{id === 1 ? "Total Seller" : "Total Revenue"}</p>
                        </div>

                        <div className={`d-flex justify-content-between p-3 pl-3 ${styles.mobileBoxes} w-100 `}>
                            <div>
                                <p className="" style={{ fontSize: "18px", color: "black", fontWeight: "600" }}>Total Order</p>
                                <p className={`mt-1 mb-1`}>{allDashboardData?.total_order}</p>

                            </div>

                            <img src={ordericon} width={30} height={30} />

                        </div>

                        <div className="d-flex w-100" style={{ gap: "15px" }}>
                            <div className={`d-flex justify-content-between w-50 ${styles.mobileBoxes}  `}>
                                <div className="p-2">
                                    <p className={styles.orderBreakdowntexts} >Accepted</p>
                                    <p className={`mt-1 mb-1`}>{options?.data[1].value}</p>

                                </div>

                            </div>
                            <div className={`d-flex justify-content-between w-50 ${styles.mobileBoxes}  `}>
                                <div className="p-2">
                                    <p className={styles.orderBreakdowntexts}>In-progress</p>
                                    <p className={`mt-1 mb-1`}>{options?.data[2].value}</p>

                                </div>


                            </div>
                        </div>
                        <div className="d-flex w-100" style={{ gap: "15px" }}>
                            <div className={`d-flex justify-content-between w-50 ${styles.mobileBoxes}  `}>
                                <div className="p-2">
                                    <p className={styles.orderBreakdowntexts}>Delivered</p>
                                    <p className={`mt-1 mb-1`}>{options?.data[0].value}</p>

                                </div>

                            </div>
                            <div className={`d-flex justify-content-between w-50 ${styles.mobileBoxes}  `}>
                                <div className="p-2">
                                    <p className={styles.orderBreakdowntexts}>Cancelled</p>
                                    <p className={`mt-1 mb-1`}>{options?.data[3].value}</p>

                                </div>


                            </div>
                        </div>



                        <div className={`d-flex justify-content-between p-3 pl-3 ${styles.mobileBoxes} w-100 `}>
                            <div>
                                <p className="" style={{ fontSize: "18px", color: "black", fontWeight: "600" }}>Open Tickets</p>
                                <p className={`mt-1 mb-1`}>{allDashboardData?.igm_issues_count}</p>

                            </div>
                            <img src={requesticon} width={30} height={30} />

                        </div>
                    </div>

                    <div>
                        <AgChartsReact options={chartOptions} />
                    </div>

                    <div className="my-3 d-flex" style={{ borderRadius: "8px", gap: "10px" }}>

                        <div className="p-2" style={{ width: "50%", background: "white", borderRadius: "10px" }}>
                            <p style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Delivered Orders</p>

                            <div>
                                <div className={styles.spinnerBox} style={{ border: "16px solid #f3f3f3", borderTop: `15px solid blue`, borderRight: `16px solid blue`, borderBottom: `15px solid blue` }}>
                                    <p  > {percentageCompleted.toFixed(1)}%</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2" style={{ width: "50%", background: "white", borderRadius: "10px" }}>
                            <p style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Orders In-Progress</p>

                            <div>
                                <div className={styles.spinnerBox} style={{ border: "16px solid #f3f3f3", borderTop: "15px solid blue" }}>
                                    <p  > {percentageCompletedInprg.toFixed(1)}%</p>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div> : <div style={{ minHeight: "90vh", color: "black" }} className="d-flex justify-content-center align-items-center">
                <h5 className="text-center">You are not Authorized to access dashboard, Please contact Admin !</h5> </div>}
        </ThemeMaker >


    )
}

export default Dashboard; 
