
import { useState } from "react"
import axios from "axios"
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../../utils/config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
export default function Form1() {

    const params = useParams()

    const [form1State, setForm1State] = useState({
        insauranceType: "",
        formData: {
            "f_name": "",
            "l_name": "",
            "email": "",
            "phone": "",
            "ped_detail": "",
            "dob": "",
            "gender": "",
            "pan_no": "",
            "diabetes": "",
            "blood_pressure": "",
            "heart_ailment": "",
            "other_health_issue": "",
            "weight": "",
            "height": "",
            "coverage_amt": "",
            "pan_india_cover": "",
            "pincode": "",
            "ondc_form_id": "",
            "action": "search",
            "type": "self",
            "relationship_with_proposer": "",
            submission_id:""

        }
    })

    const [isSubmitting, setisSubmitting] = useState(false)

    function onChangeHandler(e) {
        setForm1State(prev => {
            return { ...prev, formData: { ...prev.formData, [e.target.name]: e.target.value } }
        })
    }
    console.log(form1State?.formData)
    async function submitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        function checkFormData(data) {
            for (const key in data) {
                if (key === "ondc_form_id" || key==="relationship_with_proposer" || key === "submission_id") {
                    continue;
                }
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    return false;
                }
            }
            return true;
        }

        if (checkFormData(form1State.formData)) {
            try {
                setisSubmitting(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/admin/api/searchInsForm`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        ...form1State.formData,
                        ped_detail: Boolean(form1State.formData.ped_detail),
                        diabetes: Boolean(form1State.formData.diabetes),
                        blood_pressure: Boolean(form1State.formData.blood_pressure),
                        heart_ailment: Boolean(form1State.formData.heart_ailment),
                        other_health_issue: Boolean(form1State.formData.other_health_issue),
                        pan_india_cover: Boolean(form1State.formData.pan_india_cover),
                        ondc_form_id: params?.id // Assuming formId is defined elsewhere
                    }
                });

                if (data.success) {
                    toast.success("Form Submitted Successfully.");
                } else {
                    toast.error("Error In Submitting Form.");
                }
            } catch (err) {
                toast.error("Server not working, Please try again later!");
            } finally {
                setisSubmitting(false); // Ensure this is called in all cases
            }
        } else {
            toast.error("Please fill all fields!");
        }
    }


    return (

        <div className="row w-100 m-0 mt-1">
            <div className="col-md-12 ">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center">
                            Insaurance  Form
                            <div className="d-flex" style={{ gap: "20px" }}>
                                {/* <button className="text-center btn btn-secondary" onClick={() => {
                                        setshipmentModalOpen(true)
                                    }}>Import</button>
                                    <Link to="/producttypelist" >
                                        <button className="btn btn-primary">Product Type List</button>
                                    </Link> */}
                            </div>


                        </h6>
                    </div>

                    <div className="card-body" style={{ minHeight: "90vh" }}>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Need Insaurance for
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select id="" name="type" value={form1State?.formData?.type} className='form-control' onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="self">Self</option>
                                        <option value="family">Family</option>
                                    </select>
                                </div>

                            </div>

                        </div>

                        <div className="row p-0 m-0">

                            {form1State?.formData?.type === "family" && <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Relationship
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="relationship_with_proposer" id="" className='form-control' value={form1State.formData.relationship_with_proposer} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="wife">Wife</option>
                                        <option value="husband">Husband</option>
                                        <option value="son">Son</option>
                                        <option value="daughter">Daughter</option>
                                        <option value="spouse">Spouse</option>
                                        <option value="father">Father</option>
                                        <option value="mother">Mother</option>
                                    </select>
                                </div>

                            </div>}


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        First Name
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="f_name" value={form1State.formData.f_name} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Last Name
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="l_name" value={form1State.formData.l_name} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Email
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="email" className="form-control" value={form1State.formData.email} onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Phone
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="phone" className="form-control" value={form1State.formData.phone} onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        DOB
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="dob" value={form1State.formData.dob} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        PAN Number
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="pan_no" value={form1State.formData.pan_no} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Weight
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="weight" className="form-control" value={form1State.formData.weight} onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Height
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="height" className="form-control" value={form1State.formData.height} onChange={onChangeHandler} />
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Gender
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="gender" id="" className='form-control' value={form1State.formData.gender} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        PED
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="ped_detail" id="" className='form-control' value={form1State.formData.ped_detail} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Diabeties
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="diabetes" id="" className='form-control' value={form1State.formData.diabetes} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Blood Pressure
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="blood_pressure" id="" className='form-control' value={form1State.formData.blood_pressure} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Heart Problem
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="heart_ailment" id="" className='form-control' value={form1State.formData.heart_ailment} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Other Health Problem
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="other_health_issue" id="" className='form-control' value={form1State.formData.other_health_issue} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        PAN India Cover
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="pan_india_cover" id="" className='form-control' value={form1State.formData.pan_india_cover} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Coverage Amount
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="coverage_amt" className="form-control" value={form1State.formData.coverage_amt} onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Pincode
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="pincode" className="form-control" value={form1State.formData.pincode} onChange={onChangeHandler} />
                                </div>

                            </div>

                        </div>

                        <div className="row p-1 ml-2">

                            <button className="btn btn-primary" onClick={submitHandler} disabled={isSubmitting} >Submit</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
