import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { Link } from 'react-router-dom';


export default function FinanceOrder() {

    const [financeOrderList, setFinanceOrderList] = useState([])

    useEffect(() => {
        getFinanceOrderList();
    }, [])

    async function getFinanceOrderList() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/getOrderDetails`,
                method: "GET"
            })
            setFinanceOrderList(data?.status === 200 ? data.data : []);
        } catch (err) {
            console.log(err)
            toast.err("Error fetching sellers!")
        }
    }


    const COLUMNS = [
        {
            Header: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Provider Name",
            accessor: (item) => (item?.provider_name ? item?.provider_name : ""),
            sticky: "left"
        },
        {
            Header: "Provider Id",
            accessor: (item) => (item?.provider_id ? item?.provider_id : ""),
            sticky: "left",
        },
        {
            Header: "Order Id",
            accessor: (item) => (item?.order_id ? item?.order_id : ""),
            sticky: "left",
        },
        {
            Header: "Order Status",
            accessor: (item) => (item?.order_status ? item?.order_status : ""),
            sticky: "left",
        },
    ];

    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left">Finace Order List</h6>
                        </div>
                        <div className="card-body p-0 p-1" style={{ minHeight: "70vh" }}>
                            <Paginated data={financeOrderList} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}
