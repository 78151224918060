/* eslint-disable no-duplicate-case */
import { useEffect } from "react";
import { useHistory, useLocation, } from "react-router-dom";
// import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { tokenDecode } from "../../utils/helper";
import allorderslist from "../../pages/allorderslist";

const ProtectedRoute = ({ children }: any) => {
    const history = useHistory();
    const location = useLocation();
    const search = useLocation().search;
    const tk_access = new URLSearchParams(search).get('tk_access');
    const decoded = tokenDecode();
    // const token: any = sessionStorage.getItem("us_st_d");
    // const TokenChecker = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: "/login/refresh",
    //             method: "POST",
    //             headers: {
    //                 "x-custom-authorization": `Bearer ${token}`
    //             }
    //         });
    //         localStorage.setItem("isLogedIn", data.refreshToken);
    //     } catch (error) {
    //         localStorage.removeItem("isLogedIn");
    //     }
    // }
    useEffect(() => {
        //   TokenChecker();
    }, []);
    const keyV = location.pathname.split("/")[1];
    try {
        const decoded: any = tokenDecode(tk_access);
        const { data } = decoded;
        const { permission: { category, order, product, role, seller, tax, user, location, igm, logistic, transaction } } = data;
        // console.log("hhhh", logistic);

        if (decoded) {
            // if (isAdmin === "true") {
            //     return children;
            // }
            switch (keyV) {
                case "":
                    return children;
                case "dashboard":
                    return children;
                case "addseller":
                    return (seller.write || decoded?.data?.seller_data?.is_multi_shop === true) ? children : history.push("/")
                case "sellerlist":
                    return (seller.read || decoded?.data?.seller_data?.is_multi_shop === true) ? children : history.push("/");
                case "tax":
                    return tax.write ? children : history.push("/");
                case "updateseller":
                    return (seller.update || decoded?.data?.seller_data?.is_multi_shop === true) ? children : history.push("/");
                case "roles":
                    return role.write ? children : history.push("/")
                case "adduser":
                    return user.write ? children : history.push("/");
                case "updateuser":
                    return user.update ? children : history.push("/");
                case "userlist":
                    return user.read ? children : history.push("/");
                case "category":
                    return category.write ? children : history.push("/");
                case "product":
                    return product.write ? children : history.push("/");
                case "productlist":
                    return product.read ? children : history.push("/");
                case "orderlist":
                    return order.read ? children : history.push("/");
                case "orderdetails":
                    return order.read ? children : history.push("/");
                case "transaction":
                    return transaction.read ? children : history.push("/");
                case "location":
                    return location.read ? children : history.push("/");
                case "returnorders":
                    return order.read ? children : history.push("/");
                case "issues":
                    return igm.read ? children : history.push("/");
                case "onissues":
                    return igm.read ? children : history.push(("/"));
                case "payments":
                    return transaction.read ? children : history.push(("/"));
                case "return":
                    return transaction.read ? children : history.push(("/"));
                case "mastercatalog":
                    return children;
                case "logrejection":
                    return children;
                case "masterproductlist":
                    return children;
                case "ondcsellerproduct":
                    return children;
                case "ondcsellerproductadd":
                    return children;
                case "nonapprovedproducts":
                    return children;
                case "productcatalog":
                    return children;

                case "nonapprovedproducts":
                    return children;
                case "nonapprovedproduct":

                    return children;
                case "notifications":
                    return children;
                case "sellernonapprovedproducts":
                    return children;
                case "test":
                    return children;

                case "profile":
                    return children;
                case "sellerdetail":
                    return children;
                case "logs":
                    return children;
                case "sidebar":
                    return children;
                case "ondcsellerapp":
                    return children;
                case "selleregister":
                    return children;
                case "personaldetails":
                    return children;
                case "bankdetails":
                    return children;
                case "checkseller":
                    return children;
                case "pending":
                    return children;

                case "catalog":
                    return product.read ? children : history.push("/");
                case "addinstoreproduct":
                    return product.read ? children : history.push("/");
                case "instoreproducts":
                    return product.read ? children : history.push("/");
                case "order":
                    return order.read ? children : history.push("/");

                case "allorderslist":
                    return children;
                case "orderCompleteDetail":
                    return order.read ? children : history.push("/");
                case "orderCompleteinfo":
                    return order.read ? children : history.push("/");
                case "customerorder":
                    return order.read ? children : history.push("/");
                case "completeOrder":
                    return order.read ? children : history.push("/");
                case "cancelOrderlist":
                    return order.read ? children : history.push("/");
                case "brand":
                    return children;
                case "sellerauthcheck":
                    return children;
                case "newcategory":
                    return children;
                case "seeorderdetails":
                    return children;
                case "acceptedorderdetail":
                    return children;
                case "orderpopup":
                    return children;
                case "updatestoreproduct":
                    return children;
                case "invoice":
                    return children;
                case "order_invoice":
                    return children;
                case "supportlist":
                    return children;
                case "rspdashboard":
                    return children;
                case "updatepassword":
                    return children;
                case "settings":
                    return children;
                case "serviceability":
                    return children;
                case "variants":
                    return children;
                case "discount":
                    return children;
                case "discountbyOrderAmount":
                    return children;
                case "discountbyOrderAmount":
                    return children;
                case "producttype":
                    return children;
                case "producttypelist":
                    return children;
                case "resetpassword":
                    return children;
                case "unsettledtransactions":
                    return transaction.read ? children : history.push("/");
                case "adminsettings":
                    return children;
                case "shippingchargelist":
                    return children;
                case "addshippingcharge":
                    return children;
                case "socialtagging":
                    return children;
                case "createcustomization":
                    return children;
                case "customizationlist":
                    return children;
                case "logistic":
                    return children;
                case "ordercreate":
                    return children;
                case "finance":
                    return children;
                default:
                    return history.push("/");
            }

        } else {
            console.log("no");
            switch (keyV) {
                case "forget-password":
                    return children;
                case "reset-password":
                    return children;
                default:
                    return history.push("/");
            }
        }
    } catch (error) {
        console.log("this erro -->", error);

        switch (keyV) {
            case "forget-password":
                return children;
            case "reset-password":
                return children;
            case "test":
                return children;
            default:
                return history.push("/");
        }
    }
}


export default ProtectedRoute;
