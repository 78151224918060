import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker';

import { NodebaseUrlDefiner } from '../../utils/config';
import axios from "axios"
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { config } from '../../utils/config';


const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require("aws-sdk");

export default function Selleradd() {

    const params = useParams();
    const { id } = params;
    const initialValues = {
        "name": "",
        "category_id": 4,
        "images": [
            {
                "url": "https://refo-static-public.s3.ap-south-1.amazonaws.com/dmi/dmi-sm.png",
                "size_type": "sm"
            },
            {
                "url": "https://refo-static-public.s3.ap-south-1.amazonaws.com/dmi/dmi-md.png",
                "size_type": "md"
            },
            {
                "url": "https://refo-static-public.s3.ap-south-1.amazonaws.com/dmi/dmi-lg.png",
                "size_type": "lg"
            }
        ],
        "short_desc": "",
        "long_desc": "",
        "status": true,
        "email": "",
        "type": "personal loan",
        "lsp_name": "",
        "lsp_contact_no": "",
        "lsp_address": "",
        "lsp_email": "",
        "lsp_support_link": "",
        "gro_name": "",
        "gro_email": "",
        "gro_contact_no": "",
        "gro_customer_support_no": "",
        "gro_customer_support_email": "",
        "gro_designation": "",
        "gro_address": "",
        "charges": { "OTHER_CHARGES": "", "PROCESSING_FEE": "", "CANCELLATION_FEE": "", "INSURANCE_CHARGES": "", "OTHER_UPFRONT_CHARGES": "" },
        "fulfillment_state": "",
        "external_ref_link": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        "agreement_link": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }

    useEffect(() => {
        if (id) {
            getSellerData()
        }
    }, [])

    async function getSellerData() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/getproviderbyID/${id}`,
                method: "GET",

            })
            let responseValues = data?.data[0];
            setformData({ ...responseValues, name: responseValues?.provider_name })

            console.log(data)
        } catch (err) {
            console.log(err)
            toast.error("Error fetching sellers!")
        }

    }



    const [formData, setformData] = useState(initialValues)
    console.log("formData------", formData)
    async function onSubmitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        let url;
        console.log(id)
        if (id !== undefined) {
            url = `${apiEndpoint}/adminCredit/updateprovider`
        } else {
            url = `${apiEndpoint}/adminCredit/createprovider`
        }
        try {

            const payload = { ...formData, status: true, category_id: 4, }
            const { data } = await axios({
                method: "POST",
                url: url,
                data: payload
            })
            if (data.status === 200) {
                toast.success("Data inserted successfully!")
            }
            console.log("data----------", data)
        } catch (err) {
            console.log(err)
            toast.error("Somthing went wrong, please try later!")
        }
    }

    function onCHangeHandler(e) {
        setformData((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    function chargesChangeHandler(e) {
        setformData((prev) => {
            return {
                ...prev, charges: {
                    ...prev.charges,[e.target.name]: e.target.value
                }
            }
        })
    }


    const productImageHandler = async (e, index) => {

        try {
            const files = e.target.files;

            const uploadPromises = Array.from(files).map(async (file) => {
                let myFile = file.name.split(".");
                let myFileType = myFile[myFile.length - 1];

                const s3 = new aws.S3({
                    accessKeyId: awsKeyId,
                    secretAccessKey: awsSecretKey,
                    region: awsRegion
                });

                const params = {
                    Bucket: "ondc-marketplace",
                    Key: `images/${uuidv4()}.${myFileType}`,
                    Body: file,
                    ContentType: file.type
                };

                let result = await s3.upload(params).promise();
                return result.Location;
            });

            const locations = await Promise.all(uploadPromises);
            // Update state with all image locations
            setformData((prevProduct) => ({
                ...prevProduct,
                [e.target.name]: [...locations]
            }));
        } catch (exception) {
            toast.error("Error in Adding Product Image");
            console.log(exception);
        }
    };




    return (
        <ThemeMaker>

            <div className="row m-0 w-100 mt-1">
                <div className="col-md-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex justify-content-between">
                            <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                                Add seller
                            </h6>

                            <Link to="/finance/sellerlist"><span style={{ fontSize: "14px", textDecoration: "underline", color: "blue" }}>All Sellers</span></Link>
                        </div>
                        <div className="card-body">
                            <div className='row p-2'>

                                <div className="row">
                                    {/* Name */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Name</label>
                                            <input onChange={onCHangeHandler} value={formData?.name} className="form-control" type="text" name="name" />
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Email</label>
                                            <input onChange={onCHangeHandler} value={formData?.email} className="form-control" type="email" name="email" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Images</label>
                                            <input class="form-control" type="file" name='images' id="formFileMultiple" multiple onChange={productImageHandler} />
                                        </div>
                                    </div>

                                    {/* Short Description */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Short Description</label>
                                            <input onChange={onCHangeHandler} value={formData?.short_desc} className="form-control" type="text" name="short_desc" />
                                        </div>
                                    </div>

                                    {/* Long Description */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Long Description</label>
                                            <input onChange={onCHangeHandler} value={formData?.long_desc} className="form-control" type="text" name="long_desc" />
                                        </div>
                                    </div>

                                    {/* LSP Name */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">LSP Name</label>
                                            <input onChange={onCHangeHandler} value={formData?.lsp_name} className="form-control" type="text" name="lsp_name" />
                                        </div>
                                    </div>

                                    {/* LSP Contact */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">LSP Contact Number</label>
                                            <input onChange={onCHangeHandler} value={formData?.lsp_contact_no} className="form-control" type="text" name="lsp_contact_no" />
                                        </div>
                                    </div>

                                    {/* LSP Address */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">LSP Address</label>
                                            <input onChange={onCHangeHandler} value={formData?.lsp_address} className="form-control" type="text" name="lsp_address" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">LSP Email</label>
                                            <input onChange={onCHangeHandler} value={formData?.lsp_email} className="form-control" type="email" name="lsp_email" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">LSP Support Link</label>
                                            <input onChange={onCHangeHandler} value={formData?.lsp_support_link} className="form-control" type="text" name="lsp_support_link" />
                                        </div>
                                    </div>

                                    {/* GRO Name */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Name</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_name} className="form-control" type="text" name="gro_name" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Email</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_email} className="form-control" type="email" name="gro_email" />
                                        </div>
                                    </div>

                                    {/* GRO Contact */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Contact Number</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_contact_no} className="form-control" type="text" name="gro_contact_no" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Customer Support Email</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_customer_support_email} className="form-control" type="email" name="gro_customer_support_email" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Designation</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_designation} className="form-control" type="text" name="gro_designation" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">GRO Address</label>
                                            <input onChange={onCHangeHandler} value={formData?.gro_address} className="form-control" type="text" name="gro_address" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">External Reference Link</label>
                                            <input class="form-control" type="file" id="formFileMultiple" name="external_ref_link" multiple onChange={productImageHandler} />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Agreement Link</label>
                                            <input class="form-control" type="file" id="formFileMultiple" name="agreement_link" multiple onChange={productImageHandler} />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Fulfillment State</label>
                                            <select name="" id="">
                                                <option value="">Select Fulfillment State</option>
                                                <option value="DISBURSED">Disbursed</option>
                                                <option value="PENDING">Pending</option>
                                                <option value="SANCTIONED">Sanctioned</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className='mt-2 col-md-12'>

                                        <p className="genericlabel">Charges</p>
                                        <hr />

                                        <div className='row'>

                                            <div class="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Processing Fee</label>
                                                    <input onChange={chargesChangeHandler} value={formData?.charges?.PROCESSING_FEE} className="form-control" type="text" name="PROCESSING_FEE" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Insaurance Charge</label>
                                                    <input onChange={chargesChangeHandler} value={formData?.charges?.INSURANCE_CHARGES} className="form-control" type="text" name="INSURANCE_CHARGES" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Cancellation Fee</label>
                                                    <input onChange={chargesChangeHandler} value={formData?.charges?.CANCELLATION_FEE} className="form-control" type="text" name="CANCELLATION_FEE" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Other Upfront Charges</label>
                                                    <input onChange={chargesChangeHandler} value={formData?.charges?.OTHER_UPFRONT_CHARGES} className="form-control" type="text" name="OTHER_UPFRONT_CHARGES" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Other Charges</label>
                                                    <input onChange={chargesChangeHandler} value={formData?.charges?.OTHER_CHARGES} className="form-control" type="text" name="OTHER_CHARGES" />
                                                </div>
                                            </div>

                                        </div>




                                    </div>

                                    {/* Submit Button */}
                                    <div className="d-flex col-md-12 justify-content-end">
                                        <button type="submit" className="btn btn-primary" onClick={onSubmitHandler}>Submit</button>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </ThemeMaker>
    )
}
