import React from 'react'
import { useParams } from 'react-router-dom'

export default function Thanks() {

    const params = useParams();

    const message = {
        // "message": "Form Data Submitted Successfully",
        "submission_id": `${params.id}`
    }
    return (
        <div>

            {JSON.stringify(message)}

        </div>
    )
}
