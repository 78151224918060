
import { useState } from "react"
import axios from "axios"
import { NodebaseUrlDefiner } from "../../../utils/config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";



export default function Personalform5() {

    const params = useParams()

    const [form1State, setForm1State] = useState({

        formData: {
            "accountHolder_Name": "",
            "account_No": "",
            "ifscCode": "",
            "mandateAmount": "",
            "frequency": "",
            "startDate": "",
            "endDate": "",

        }
    })

    const [isSubmitting, setisSubmitting] = useState(false)

    function onChangeHandler(e) {
        setForm1State(prev => {
            return { ...prev, formData: { ...prev.formData, [e.target.name]: e.target.value } }
        })
    }
    console.log(form1State?.formData)
    async function submitHandler() {
        let apiEndpoint = NodebaseUrlDefiner();
        function checkFormData(data) {
            for (const key in data) {
                // let notrewuiredKeys = ["official_email", "company_name", "address", "addressL2",]
                // if (notrewuiredKeys.some(item => item === key)) {
                //     continue;
                // }
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    return false;
                }
            }
            return true;
        }

        if (checkFormData(form1State.formData)) {
            try {
                setisSubmitting(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/credit/eMandateForm`,
                    method: "POST",
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                        ...form1State.formData,mandateAmount: parseInt(form1State.formData.mandateAmount),
                        form_id: params?.id // Assuming formId is defined elsewhere
                    }
                });

                if (data.status === 200) {
                    toast.success("Form Submitted Successfully.");
                    setForm1State({
                        formData: {
                            "accountHolder_Name": "",
                            "account_No": "",
                            "ifscCode": "",
                            "mandateAmount": "",
                            "frequency": "",
                            "startDate": "",
                            "endDate": "",

                        }
                    })

                } else {
                    toast.error("Error In Submitting Form.");
                }
            } catch (err) {
                toast.error("Server not working, Please try again later!");
            } finally {
                setisSubmitting(false); // Ensure this is called in all cases
            }
        } else {
            toast.error("Please fill all fields!");
        }
    }



    return (

        <div className="row w-100 m-0 mt-1">
            <div className="col-md-12 ">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-company_name-bold text-primary text-left d-flex justify-content-between align-items-center">
                            E Mandate Form
                            <div className="d-flex" style={{ gap: "20px" }}>
                                {/* <button className="text-center btn btn-secondary" onClick={() => {
                                        setshipmentModalOpen(true)
                                    }}>Import</button>
                                    <Link to="/producttypelist" >
                                        <button className="btn btn-primary">Product Type List</button>
                                    </Link> */}
                            </div>


                        </h6>
                    </div>

                    <div className="card-body" style={{ minincome: "90vh" }}>


                        <div className="row p-0 m-0">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Account Holder Name
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="accountHolder_Name" value={form1State.formData.accountHolder_Name} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>



                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Bank Account Number
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="account_No" value={form1State.formData.account_No} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Frequency
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <select name="frequency" id="" className="form-control" value={form1State.formData.frequency} onChange={onChangeHandler}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Yearly">Yearly</option>
                                    </select>
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        IFSC Code
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="ifscCode" value={form1State.formData.ifscCode} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Mandate Amount
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="mandateAmount" value={form1State.formData.mandateAmount} className="form-control" onChange={onChangeHandler} />
                                </div>

                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        Start Date
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="startDate" value={form1State.formData.startDate} className="form-control" onChange={onChangeHandler} placeholder="YYYY-MM-DD" />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="genericlabel">
                                        End Date
                                        <span className="text-danger" > * </span>

                                    </label>
                                    <input type="text" name="endDate" value={form1State.formData.endDate} className="form-control" onChange={onChangeHandler} placeholder="YYYY-MM-DD" />
                                </div>

                            </div>



                        </div>

                        <div className="row p-1 ml-2">

                            <button className="btn btn-primary" onClick={submitHandler} disabled={isSubmitting} >Submit</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
