import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import { Link } from 'react-router-dom'
import { Paginated } from '../../components/Paginated'
import axios from 'axios';
import { baseUrlDefiner } from '../../utils/config';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Circularprogress from "../../components/Generics/Circularprogress";

const Sstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};

export default function Customizationlist() {

    const [productList, setproductList] = useState([]);
    const [deleteModalOpen, setdeleteModalOpen] = useState(false);
    const [groupDeleteId, setgroupDeleteId] = useState("");
    const [isLoading, setisLoading] = useState(null)

    useEffect(() => {
        getCustomizations()
    }, [])

     async function getCustomizations() {
        setisLoading(true)
        const apiEndpoint = baseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/getgroup_customizations`,
            method: "POST",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },

        });

        if (data?.status === 200) {
            setproductList(data?.data);

        } else {

            toast.error("Something went wrong!")
        }
        setisLoading(false)
        console.log("data------", data)
    }

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },

            sticky: "left"
        },
        {
            Header: "Menu Name",
            Footer: "Menu Name",
            accessor: "menu_name",
            sticky: "left"
        },
        {
            Header: "Group Name",
            Footer: "Group Name",
            accessor: "grouping_name",
            sticky: "left"
        },
        {
            Header: "Customizations",
            Footer: "Customizations",
            accessor: "customization_names",
            sticky: "left"
        },
        {
            Header: "Actions",
            Footer: "Actions",
            accessor: (row) => (
                <> <Link to={`/createcustomization/${row.group_id}`}> <button className='btn btn-sm btn-success'>Edit</button>  </Link>
                    <button className='btn btn-sm btn-danger' onClick={() => { setdeleteModalOpen(true); setgroupDeleteId(row?.group_id) }}>Delete</button>
                </>
            ),
            sticky: "left"
        },

    ];

    async function deleteGroupHandler(id) {
        if (!id) {
            toast.error("No id is present for this customization group.")
            return
        }
        try {
            const apiEndpoint = baseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/deletegroupcustomization`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "group_id": [id]
                }
            });

            if (data?.status === 200) {
                toast.error("Customization Group Deleted!");
                getCustomizations()
            } else {
                toast.error("Error in deleting!")
            }

            setdeleteModalOpen(false)

            console.log("data------", data)
        } catch (err) {
            toast.error("Internal server error!")
        }
    }



    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-2">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Customizations
                            </h6>

                            <Link to="/createcustomization">    <span style={{ color: "blue", textDecoration: "underline", fontSize: "12px" }}>Add Customizations</span> </Link>
                        </div>

                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            {(!isLoading && productList.length > 0) && <Paginated data={productList} columns={COLUMNS} />}
                            {(!isLoading && productList.length === 0) && <div style={{ height: "60vh" }}>

                                <p className='text-center' style={{ fontSize: "20px", fontWeight: "700", color: "black" }}>No Data found</p>

                            </div>}

                            {isLoading && <Circularprogress />}
                        </div>

                        <Modal
                            open={deleteModalOpen}
                            // onClose={() => setdeleteModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={Sstyle} className="col-md-4 ">

                                <div className="modal-content " >
                                    <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                        <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}> Delete Customization Group  </p>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span style={{ cursor: "pointer" }} ><CloseIcon onClick={() => { setdeleteModalOpen(false); setgroupDeleteId("") }} /></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <h3 style={{ fontSize: "20px", color: "black", fontWeight: "700", textAlign: "center" }}>Are you sure?</h3>

                                        <div className='d-flex justify-content-end mt-5' style={{ gap: "13px" }}>
                                            <button className='btn btn-primary' onClick={() => { setdeleteModalOpen(false); setgroupDeleteId("") }}>Cancel</button>
                                            <button className='btn btn-danger' onClick={() => {
                                                deleteGroupHandler(groupDeleteId)
                                            }}>Delete</button>
                                        </div>

                                    </div>

                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}
